const baseButton = {
    textTransform: 'capitalize',
    padding: '6px 30px',
};
export const CreateMachineDialogStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '11px',
    },

    cancelButton: { ...baseButton, color: '#1424f9', border: '1px solid #1424f9' },
    createButton: { ...baseButton, background: '#1424f9' },
    dialogContent: { padding: '11px 22px 11px 22px' },
    dialogAction: { padding: '0px 22px 11px 22px' },
    selectField: {
        width: '100%',
        height: '100%',
        '>div': {
            padding: '3px 10px',
        },
    },
    textInput: {
        padding: '3px 10px',
        '& input::placeholder': {
            color: '#6D6F81',
            opacity: 1,
        },
    },
};
