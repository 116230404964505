import React from 'react';
import { Typography } from 'nxg-ui-wrapper';
import { colors } from 'src/global/style.object';

interface IPlaceHolderProps {
    text: string;
}
const PlaceHolder = (props: IPlaceHolderProps) => {
    const { text } = props;
    return <Typography color={colors.inputPlaceholder}>{text}</Typography>;
};

export default PlaceHolder;
