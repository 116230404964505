import { useAuthenticated } from 'src/hooks/context';
import { BaseCommon, TypeCommon } from 'src/common';
import { ExtLogicS3 } from '../business';
import { CustomError, IFetchS3Type, ITriggerErrorType } from '../types';
import { TypeContext } from 'src/store';

interface IUseTimer {
    clearTimer: () => void;
    startTimer: () => void;
    handleRefresh: () => Promise<void>;
    handleError: ({ message, otherMessage }: CustomError) => void;
}

const useTimer = (): IUseTimer => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const { intervalId, timer } = appState;

    const clearTimer = (): void => {
        if (intervalId) {
            clearInterval(intervalId);
        }
    };

    const startTimer = (): void => {
        let timerTmp = timer;
        const intervalIdTmp: undefined | NodeJS.Timer = setInterval(() => {
            if (timerTmp <= BaseCommon.FILE_REFRESH_TIME_SECOND) {
                dispatch(TypeCommon.setTimer(BaseCommon.APP_INTERVAL_TIME_SECOND));
                timerTmp = BaseCommon.APP_INTERVAL_TIME_SECOND;
            } else {
                timerTmp = timerTmp - 1;
                dispatch(TypeCommon.setTimer(timerTmp));
            }
        }, BaseCommon.SECOND_TO_MILLISECOND);
        dispatch(TypeCommon.setIntervalId(intervalIdTmp));
    };

    const handleError = ({ message, otherMessage }: CustomError): void => {
        const payload: ITriggerErrorType = { message, otherMessage };
        dispatch(TypeCommon.setTriggerErrorType(payload));
    };

    const handleRefresh = async (): Promise<void> => {
        try {
            const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();
            const s3Blocking = await ExtLogicS3.fetchBlockingFromS3();

            const payload: IFetchS3Type = {
                s3File: s3Data,
                isOtherProcessing: s3Blocking.totalSize > 0,
            };
            dispatch(TypeCommon.setFetchS3Type(payload));
        } catch (error: any) {
            handleError({
                ...error,
                message: error.message,
                otherMessage: error.message,
            });
        } finally {
            clearTimer();
            startTimer();
            dispatch(TypeCommon.setAuto(true));
        }
    };

    return {
        handleRefresh,
        handleError,
        startTimer,
        clearTimer,
    };
};

export default useTimer;
