import { AlertColor } from '@mui/material/Alert';
import { AmplifyS3Config, S3Response } from '../custom/type';
import { MemoExoticComponent } from 'react';

export class S3Object {
    eTag!: string;
    key!: string;
    lastModified!: string;
    size!: number;
}

export class FileDb3 extends File {
    path: string | undefined;
}

export class Notify {
    isOpen = false;
    message = '';
    type?: AlertColor = 'success';
}

export interface CustomError {
    message: string;
    otherMessage?: string;
}

export enum BrowserStorageKey {
    S3_BUCKET_SQLITE = 'aimscs-data-importer-s3-bucket-sqlite',
    S3_BUCKET_CLIENT_BLOCKING = 'aimscs-data-importer-s3-bucket-blocking',
    S3_REGION = 'aimscs-data-importer-s3-region',
}

export enum BucketType {
    SQLITE,
    BLOCKING,
}

export enum EnvironmentMode {
    DEV,
    REL,
}

export interface ISetBucket {
    rdsBuckets: AmplifyS3Config<BucketType>[];
}

export interface IUploadType {
    uploadingFile: FileOfBucket;
}

export interface IThrowErrorUploadType extends IUploadType {}

export interface IFetchS3Type {
    s3File: S3StatusBucket;
    isOtherProcessing: boolean;
}

export interface ITriggerLoadingType {
    isScanning: boolean;
}

export interface ICompletedScanningType {
    equipmentId: string;
    uploadingFile: FileOfBucket;
    isUploaded?: boolean;
}

export interface ITriggerErrorType {
    message: string;
    otherMessage?: string;
}

export enum ENotifyType {
    ERROR = 'error',
    SUCCESS = 'success',
}

export interface INotifyType {
    notify: INotify;
}

export interface INotify {
    message: string;
    isOpen: boolean;
    type?: ENotifyType;
}
export interface UploadObjectFile {
    filename: string;
    folder: string;
    lastModified: number;
    size: number;
    progress: number;
    file: FileDb3 | null;
    isError: boolean;
}

export interface FileOfBucket {
    name: string | undefined;
    totalFile: number;
    totalSize: number;
    progress: number;
    objects: UploadObjectFile[];
    isError: boolean;
}

export interface IPageDef {
    id: string;
    name: string;
    label: string;
    navLink: string;
    iconSrc: string;
}
export interface IPageComponentDef {
    pageId: string;
    component: MemoExoticComponent<() => JSX.Element> | JSX.Element;
}
export interface S3StatusObjectFile extends S3Response {
    equipmentId: string;
}

export interface S3StatusBucket {
    name: string | undefined;
    totalFile: number;
    totalSize: number;
    objects: S3StatusObjectFile[];
}
export interface IBucketUrl {
    type: BucketType;
    inputLabel: string;
    url: string;
    errorMessage: string | undefined | null;
}

export interface IBucketBackDoorProperties {
    type: BucketType;
    inputLabel: string;
    errorMessage: string | null;
    dataTest: string;
    url: string | null;
}

export enum EButtonVariant {
    OUTLINED = 'outlined',
    CONTAINED = 'contained',
}
