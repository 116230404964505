import { IPageDef } from '../types';
import { IC_MACHINE_MANAGEMENT, IC_MN_ADM_GREEN } from 'src/assets';

export const pages: IPageDef[] = [
    {
        id: '1',
        name: 'data-importer',
        label: 'Data Importer',
        navLink: 'data-importer',
        iconSrc: IC_MN_ADM_GREEN,
    },
    {
        id: '2',
        name: 'machine-management',
        label: 'Machine Management',
        navLink: 'machine-management',
        iconSrc: IC_MACHINE_MANAGEMENT,
    },
];
