/* eslint-disable @typescript-eslint/no-inferrable-types */
import { days, months } from '../global/constant';
import {
    REGEX_UPPER_CASE_FIRST_LETTER,
    VARIABLE_TYPE_OBJECT,
    EMPTY_STRING,
    REGEX_CAMEL_CASE,
    REGEX_SNAKE_CASE,
    CHARACTER_MINUS,
    CHARACTER_UNDERLINE,
} from 'src/common/base.common';
const formatNumberWithCommas = (x: number | string): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const roundNumber = (num: number, decimal: number = 3): number => {
    return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};
const convertUnitFileSize = (byte: number | undefined | null): string => {
    const rootUnit = 1024;
    const nameOfUnits = ['byte', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'bb', 'hb'];

    if (byte === null || byte === undefined) {
        return 0 + ` ${nameOfUnits[0]}`;
    }

    for (const [index, unit] of Object.entries(nameOfUnits)) {
        const i = Number(index);
        if (byte <= Math.pow(rootUnit, i + 1)) {
            return formatNumberWithCommas(roundNumber(byte / Math.pow(rootUnit, i))) + ` ${unit}`;
        }
    }
    return formatNumberWithCommas(byte) + ` ${nameOfUnits[0]}`;
};

const formatTime = (timer: number): string => {
    if (timer) {
        return timer < 10 ? `0${timer}` : timer.toString();
    }
    return '';
};

const formatDate = (date: Date): string => {
    if (!date && isNaN(date)) {
        return '';
    }
    const dayTmp = date.getDay();
    const monthTmp = date.getMonth();
    const hour = JSON.stringify(date.getHours()).padStart(2, '0');
    const minute = JSON.stringify(date.getMinutes()).padStart(2, '0');
    const second = JSON.stringify(date.getSeconds()).padStart(2, '0');
    return `${days[dayTmp - 1]}, ${months[monthTmp]}-${date.getDate()}, ${date.getFullYear()} ${hour}:${minute}:${second}`;
};

export function formatDateToCustomString(date: Date): string {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function capitalizeFirstLetterEachWord(str: string): string {
    return str.replace(REGEX_UPPER_CASE_FIRST_LETTER, function (match: string): string {
        return match.toUpperCase();
    });
}

class JSONUtils {
    private static replacerObjectKeySnakeToCamel(object: any): object {
        if (object && typeof object === VARIABLE_TYPE_OBJECT && !Array.isArray(object)) {
            const replacement: any = {};
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const camelKey = key
                        .toLowerCase()
                        .replace(REGEX_SNAKE_CASE, group =>
                            group.toUpperCase().replace(CHARACTER_MINUS, EMPTY_STRING).replace(CHARACTER_UNDERLINE, EMPTY_STRING),
                        );
                    replacement[camelKey] = object[key];
                }
            }
            return replacement;
        }
        return object;
    }

    private static replacerObjectKeyCamelToSnake(object: any): object {
        if (object && typeof object === VARIABLE_TYPE_OBJECT && !Array.isArray(object)) {
            const replacement: any = {};
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const snakeKey = key.split(REGEX_CAMEL_CASE).join(CHARACTER_UNDERLINE).toLowerCase();
                    replacement[snakeKey] = object[key];
                }
            }
            return replacement;
        }
        return object;
    }

    public static convertKeySnakeToCamel(object: object, excludedLevels: number[], index: number = 0): object {
        const isNotConvertedLevel = excludedLevels?.includes(index);
        if (!isNotConvertedLevel) {
            object = JSONUtils.replacerObjectKeySnakeToCamel(object);
        }
        Object.keys(object).forEach(objectKey => {
            if (typeof object[objectKey] === VARIABLE_TYPE_OBJECT) {
                object[objectKey] = this.convertKeySnakeToCamel(object[objectKey], excludedLevels, index + 1);
            }
        });
        return object;
    }

    public static convertKeyCamelToSnake(object: object, excludedLevels: number[], index: number = 0): object {
        const isNotConvertedLevel = excludedLevels?.includes(index);
        if (!isNotConvertedLevel) {
            object = JSONUtils.replacerObjectKeyCamelToSnake(object);
        }
        Object.keys(object).forEach(objectKey => {
            if (typeof object[objectKey] === VARIABLE_TYPE_OBJECT) {
                object[objectKey] = this.convertKeyCamelToSnake(object[objectKey], excludedLevels, index + 1);
            }
        });
        return object;
    }
}

export { convertUnitFileSize, formatTime, formatDate, JSONUtils };
