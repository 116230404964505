export const InputFieldStyle = {
    wrapper: {
        display: 'flex',
        border: '1px solid #B0B4C3',
        borderRadius: '5px',
        width: 'fit-content',
        '&>:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            width: '250px',
            background: 'linear-gradient(180deg, #D6DAE6 0%, #C3C8DB 100%)',
            borderRadius: '4px 0px 0px 4px',
        },
        '&>:nth-child(2)': {
            width: '350px',
        },
    },

    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: '#2e3157',
        '&>:nth-child(1)': {
            fontWeight: 600,
        },
        '&>:nth-child(2)': {
            color: '#B40000',
        },
    },
    inputChildren: {
        '>*': {
            width: '100%',
        },
    },
};
