import { Box } from 'nxg-ui-wrapper';
import { styled } from '@mui/material/styles';
import { IC_MN_ADM_GREY } from '../../assets';
import { TestCommon } from 'src/common';
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontStyle: 'italic',
    color: '#6D6F81',
}));

interface NoDataProps {
    labelText: string;
}
const NoData = (props: NoDataProps): JSX.Element => {
    return (
        <StyledGridOverlay data-testid={TestCommon.NO_DATA_DATA_TEST_ID}>
            <img src={IC_MN_ADM_GREY} />
            <Box sx={{ mt: 1 }}>{props.labelText}</Box>
        </StyledGridOverlay>
    );
};

export default NoData;
