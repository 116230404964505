const baseButton = {
    textTransform: 'capitalize',
    padding: '6px 30px',
};
export const DeleteMachineDialogStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '10px',
    },

    cancelButton: {
        ...baseButton,
        color: '#B40000',
        border: '1px solid #B40000',
        '&:hover': {
            border: '1px solid #B40000',
            opacity: 0.8,
            background: '#ffffff',
        },
    },
    deleteButton: {
        ...baseButton,
        background: '#B40000',
        '&:hover': {
            background: '#B40000',
            opacity: 0.8,
        },
    },
    dialogContent: { padding: '11px 22px 11px 22px' },
    dialogAction: { padding: '0px 22px 11px 22px' },
    machineItemInfo: {
        display: 'flex',
        background: '#ffffff',
        position: 'relative',
        border: '.12rem solid #D9DADE',
        marginTop: '11px',
        borderRadius: '3px',
        '&>:nth-child(1)': {
            width: '10rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '&>:nth-child(2)': {
            padding: '.5rem 2rem',
            fontWeight: '600',
            '>*': {
                margin: '0px',
                '&:nth-child(2)': {
                    color: '#32549D',
                },
            },
        },
    },

    timeZoneValue: {
        color: '#40414b',
        fontWeight: '400',
    },
};
