import { ThemeOptions, SnackbarOrigin } from '@mui/material';
import { BaseCommon } from '../common';

export const colors = {
    blackText: '#191B25',
    blueButton: '#1424F9',
    disabledButton: '#d9dade',
    white: '#ffffff',
    highlightText: '#006C70',
    borderColor: '#0000001A',
    subHeadTable: '#f1f3f7',
    progressColor: '#A0B1BD',
    linearTableHead: 'linear-gradient(180deg, #D6DAE6 0%, #C3C8DB 100%);',
    linearTableRow: 'linear-gradient(180deg, #E5F2FD 0%, #E2E9FA 100%);',
    linearSuccessProgress: 'linear-gradient(90deg, #00A888 0%, #00D908 100%);',
    errorColor: '#E43539',
    successMessage: '#009F29',
    checkboxOutline: '#828288',
    checkedCheckBox: '#00b220',
    checkedCheckBoxOutLine: '#008919',
    processingButton: '#EDEEF2',
    timeZone: '#191B25',
    tableBorder: '#D0DBE5',
    headTableBorder: '#E2E9FA',
    circleProgress: '#00de00',
    inputPlaceholder: '#6D6F81',
    validationError: '#B40000',
};

export const themeRoot: ThemeOptions = {
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: '0 !important',
                    margin: '0 !important',
                    color: '#191B25',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#e7f2fe',
                        color: '#2E3157',
                    },
                    '&:hover': {
                        backgroundColor: '#e7f2fe',
                    },
                    '&.Mui-disabled': {
                        background: '#e7e9ed',
                        textFillColor: '#2E3157',
                    },
                    input: {
                        '&.MuiSelect-select': {
                            color: 'red',
                        },
                    },
                },
                selected: {},
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    input: {
                        '&.Mui-disabled': {
                            textFillColor: '#2E3157',
                        },
                        color: '#2E3157',
                    },
                    '&.Mui-disabled': {
                        background: '#e7e9ed',
                        opacity: '1',
                    },
                },
            },
        },
        MuiSelect: {
            variants: [
                {
                    style: {
                        color: '#2E3157',
                        '& .MuiSelect-select': {
                            backgroundColor: 'transparent',
                            '&:focus': {
                                backgroundColor: 'transparent', // Set the background color on focus
                            },
                        },
                    },
                    props: {},
                },
            ],
        },
    },
    typography: {
        fontFamily: [
            'Arial',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            'sans-serif',
        ].join(','),
    },
};
export const anchorOriginTopCenter: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };
export const autoHideDuration = BaseCommon.APP_INTERVAL_TIME_SECOND * 0.7 * BaseCommon.SECOND_TO_MILLISECOND;
