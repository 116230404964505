import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Box,
    Button,
    InputBase,
    Radio,
    FormControlLabel,
    RadioGroup,
    DateTimePicker,
} from 'nxg-ui-wrapper';
import InputField from '../InputField';
import TextCommon from 'src/common/text.common';
import { DeleteMachineLogDialogStyle as styles } from './DeleteMachineLogDialogStyle';
import { TypeCommon, TestCommon, BaseCommon } from 'src/common';
import { TypeContext } from 'src/store';
import { useAuthenticated } from 'src/hooks/context';
import { IDeleteMachineLogItem, IDialogConfirmData } from 'src/types/MachineManagement';
import dayjs from 'dayjs';
import { DATE_TIME_PICKER_FORMAT, TEXT_FIELD_VARIANT } from 'src/common/base.common';
import { EButtonVariant } from 'src/types';
import './DeleteMachineLogDialog.css';

interface IDeleteMachineLogDialogProps {
    machineLogToDelete: IDeleteMachineLogItem;
    saveData: (data: IDialogConfirmData) => void;
}
const DeleteMachineLogDialog = (props: IDeleteMachineLogDialogProps) => {
    const { machineLogToDelete, saveData } = props;
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMode, setDeleteMode] = useState(BaseCommon.DELETE_MODE_BEFORE);
    const setCloseDialog = () => dispatch(TypeCommon.setIsOpenDeleteMachineLogDialog(false));
    const setOpenConfirmDialog = () => dispatch(TypeCommon.setIsOpenDeleteMachineLogConfirmationDialog(true));

    const handleDateChange = (newValue: any) => {
        if (newValue && newValue.$d && dayjs(newValue.$d).isValid()) {
            setSelectedDate(newValue.$d);
        } else {
            setSelectedDate(null);
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteMode(event.target.value);
        if (event.target.value === BaseCommon.DELETE_MODE_All) {
            setSelectedDate(null);
        }
    };

    const setDataConfirmDialog = () => {
        const formattedDate: string | null = selectedDate ? dayjs(selectedDate).format(BaseCommon.FORMAT_DATE_TIME) : selectedDate;
        const dialogConfirmData: IDialogConfirmData = {
            dateTime: formattedDate,
            mode: selectedMode,
        };
        saveData(dialogConfirmData);
    };

    const isButtonEnabled: boolean = selectedMode === BaseCommon.DELETE_MODE_All || selectedDate !== null;

    if ((machineLogToDelete && Object.keys(machineLogToDelete)) || machineLogToDelete?.id) {
        return (
            <Dialog maxWidth="md" PaperProps={{ style: styles.paper }} fullWidth={true} open={true}>
                <DialogContent sx={styles.dialogContent} data-testid={TestCommon.DELETE_MACHINE_LOG_DIALOG_DATA_TEST_ID}>
                    <Typography variant="h5">{TextCommon.DELETE_MACHINE_LOG_TITLE}</Typography>
                    <Box sx={styles.formWrapper}>
                        <InputField label={TextCommon.MACHINE_ID_LABEL}>
                            <InputBase sx={styles.textInput} type="text" disabled value={machineLogToDelete.id} name="id" />
                        </InputField>
                        <InputField label={TextCommon.DELETE_MODE_LABEL}>
                            <RadioGroup name="row-radio-buttons-group" value={selectedMode} onChange={handleRadioChange} row>
                                <FormControlLabel
                                    className="delete-mode-radio"
                                    value={BaseCommon.DELETE_MODE_BEFORE}
                                    control={<Radio />}
                                    label={TextCommon.DELETE_MODE_BEFORE}
                                />
                                <FormControlLabel
                                    className="delete-mode-radio"
                                    value={BaseCommon.DELETE_MODE_AFTER}
                                    control={<Radio />}
                                    label={TextCommon.DELETE_MODE_AFTER}
                                />
                                <FormControlLabel
                                    className="delete-mode-radio"
                                    value={BaseCommon.DELETE_MODE_All}
                                    control={<Radio />}
                                    label={TextCommon.DELETE_MODE_ALL}
                                />
                            </RadioGroup>
                        </InputField>
                        <InputField label={TextCommon.DATE_TIME_LABLE}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        variant: TEXT_FIELD_VARIANT,
                                        InputProps: { disableUnderline: true },
                                    },
                                }}
                                className="my-datepicker-class"
                                disableHighlightToday
                                onChange={handleDateChange}
                                value={selectedDate}
                                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                format={DATE_TIME_PICKER_FORMAT}
                                disabled={selectedMode === BaseCommon.DELETE_MODE_All}
                            />
                        </InputField>
                    </Box>
                </DialogContent>
                <DialogActions sx={styles.dialogAction} data-testid={TestCommon.DELETE_MACHINE_LOG_DIALOG_ACTION_DATA_TEST_ID}>
                    <Button name="cancel" variant={EButtonVariant.OUTLINED} sx={styles.cancelButton} onClick={setCloseDialog}>
                        {TextCommon.CANCEL}
                    </Button>
                    <Button
                        name="delete"
                        variant={EButtonVariant.CONTAINED}
                        disabled={!isButtonEnabled}
                        sx={styles.deleteButton}
                        onClick={() => {
                            setOpenConfirmDialog();
                            setDataConfirmDialog();
                        }}
                    >
                        {TextCommon.DELETE}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return <></>;
};

export default React.memo(DeleteMachineLogDialog);
