import React from 'react';
import { Box } from 'nxg-ui-wrapper';
import { TextCommon, TestCommon } from 'src/common';
import styles from './TotalLabel.module.css';
interface ITotaLabelProps {
    label: string;
    value: number | string;
}
const TotalLabel = ({ label, value }: ITotaLabelProps) => {
    return (
        <Box className={styles.wrapper} data-testid={TestCommon.TOTAL_LABEL_DATA_TEST_ID}>
            <span className={styles.label}>
                {label}
                {TextCommon.COLON}
            </span>
            <span className={styles.value}>{value}</span>
        </Box>
    );
};

export default TotalLabel;
