import React from 'react';
import { Box } from 'nxg-ui-wrapper';
import Header from '../Header';

interface MainLayoutProps {
    children: React.ReactElement | null;
}

const MainLayout = ({ children }: MainLayoutProps): React.ReactElement | null => {
    return (
        <Box>
            <Header />
            <Box>
                <Box component="main">{children}</Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
