export const MachineManagementStyle = {
    machineListWrapper: {
        margin: '0px 15px',

        borderTop: 'none',
    },
    machineList: {
        display: 'flex',
        gap: '.4rem',
        flexWrap: 'wrap',
        flexDirection: 'row',
        border: '1px solid #D9DADE',
        background: '#ffffff',
        margin: '10px 20px 0px 20px',
        overflow: 'auto',
        '>div': {
            '>div:nth-of-type(1)': {
                padding: '10px 20px',
                borderBottom: '1px solid #D9DADE',
            },

            '> div:nth-of-type(2)': {
                padding: '15px 20px',
            },
        },
    },
    noDataWrapper: {
        paddingTop: '30px',
    },
    tableRow: {
        'th, td': {
            padding: '4px 20px',
        },
    },
    machineActions: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
    },
    machineAction: {
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.6',
        },
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: 7,
            height: 7,
            backgroundColor: 'rgb(59, 106, 128)',
        },
        '&.horizontalScrollbar::-webkit-scrollbar': {
            width: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d0dbe5',
            borderRadius: 8,
            border: 'none',
        },
        '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(52, 65, 245, 0.3)',
            backgroundColor: '#fff',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            boxShadow: 'inset 2px 2px 5px 0 rgba(rgb(59, 106, 128), 0.5)',
            backgroundColor: '#c5d2df',
        },
    },
};
