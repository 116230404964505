import { memo, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, Typography, Box, MUITable, ITableColumn, ITableRow, ITableComponentProps } from 'nxg-ui-wrapper';
import { FileOfBucket, UploadObjectFile } from 'src/types';
import { TypeContext } from '../../store';
import { TextCommon, BaseCommon, TestCommon } from '../../common';
import styles from './UploadingDialog.module.css';
import { useAuthenticated } from 'src/hooks/context';
import DialogFooter from './DialogFooter';
import { convertUnitFileSize } from 'src/utils';
import LinearProgress from '../LinearProgress';
import { colors } from '../../global/style.object';
import { SystemStyleObject } from '@mui/system';
import { TotalLabel, NoData, GlobalPreservedSpace } from 'src/components';

export interface UploadingDialogProps {
    open: boolean;
    data: FileOfBucket;
    isUploading: boolean;
    isUploaded: boolean;
    title: string;
    errorReason: string;
    Transition?: any;
    onClose: (event?: object, reason?: string) => void;
    onUpload: () => Promise<void>;
}

const TABLE_COLUMNS: ITableColumn[] = [
    {
        title: TextCommon.FILE,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.FILE,
    },
    {
        title: TextCommon.FOLDER,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.FOLDER,
    },
    {
        title: TextCommon.SIZE,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.SIZE,
        align: 'right',
    },
    {
        title: TextCommon.PROGRESS,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.PROGRESS,
        align: 'center',
        sx: { wordBreak: 'normal' },
    },
];

const UploadingDialog = ({
    data,
    open,
    title,
    errorReason,
    onClose,
    onUpload,
    Transition,
    isUploading,
    isUploaded,
}: UploadingDialogProps): JSX.Element => {
    const [appState]: TypeContext = useAuthenticated();
    const [bucketTables, setBucketTables] = useState<ITableComponentProps[]>([]);

    const getProgressColor = (isError: boolean, progress: number): SystemStyleObject => {
        const isCompletedProgress: boolean = progress === BaseCommon.COMPLETED_PERCENTAGE_NUM;
        return {
            backgroundColor: colors.progressColor,
            '& .MuiLinearProgress-bar': {
                background: !isCompletedProgress ? colors.linearSuccessProgress : !isError ? colors.linearSuccessProgress : colors.errorColor,
            },
        };
    };

    const renderBucketInfo = (totalFile: number, totalSize: number) => {
        return (
            <>
                <TotalLabel label={TextCommon.TOTAL_FILE} value={totalFile} />
                <TotalLabel label={TextCommon.TOTAL_SIZE} value={`${TextCommon.TILDE}${convertUnitFileSize(totalSize)}`} />
            </>
        );
    };

    const handleTableData = (bucketData: FileOfBucket) => {
        const bucketTablesTmp: ITableComponentProps[] = [];

        if (bucketData?.objects.length !== BaseCommon.DEFAULT_OBJECT_LENGTH) {
            const tableRows: ITableRow[] = bucketData?.objects?.map((file: UploadObjectFile, index: number) => {
                return {
                    key: index.toString(),
                    file: file.filename,
                    folder: file.folder,
                    size: convertUnitFileSize(file.size),
                    progress: (
                        <LinearProgress
                            progress={file.progress}
                            skip={false}
                            sx={getProgressColor(file.isError, file.progress)}
                            status={file.isError}
                        />
                    ),
                };
            });
            bucketTablesTmp.push({
                header: bucketData.name,
                rows: tableRows,
                columns: TABLE_COLUMNS,
                hasNumberColumn: true,
                customHeaderRight: renderBucketInfo(bucketData.totalFile, bucketData.totalSize),
            });
        }

        setBucketTables(bucketTablesTmp);
    };

    useEffect(() => {
        handleTableData(data);
    }, [data]);

    const renderTables = () => {
        return (
            <>
                {bucketTables.length !== BaseCommon.DEFAULT_OBJECT_LENGTH ? (
                    bucketTables.map((bucket: ITableComponentProps, index: number) => (
                        <Box className={styles.tableWrapper} key={index}>
                            <MUITable tableComponentProps={{ ...bucket }} />
                        </Box>
                    ))
                ) : (
                    <NoData labelText={TextCommon.NO_MACHINE_TEXT} />
                )}
            </>
        );
    };

    return (
        <Dialog
            PaperProps={{ style: { borderRadius: 0 } }}
            maxWidth="md"
            fullWidth={true}
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            data-testid={TestCommon.UPLOAD_DIALOG_DATA_TEST_ID}
        >
            <DialogContent className={styles['content']}>
                <Grid container className={styles['wrapper-content']}>
                    <Typography variant="h5" component="span" className={styles.title} data-testid={TestCommon.UPLOAD_EQUIPMENT_ID_DATA_TEST_ID}>
                        <GlobalPreservedSpace>{title}</GlobalPreservedSpace>
                    </Typography>
                </Grid>
                <Grid className={styles.dialogBody}>{renderTables()}</Grid>
            </DialogContent>
            <DialogActions className={styles.action} data-testid={TestCommon.UPLOAD_DIALOG_FOOTER_DATA_TEST_ID}>
                <DialogFooter
                    notify={appState?.notify}
                    handleClose={onClose}
                    isUploaded={isUploaded}
                    isUploading={isUploading}
                    handleUpload={onUpload}
                />
            </DialogActions>
        </Dialog>
    );
};

export default memo(UploadingDialog);
