import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Box, Button } from 'nxg-ui-wrapper';
import { IMG_NO_MACHINE_IMAGE } from 'src/assets';
import TextCommon from 'src/common/text.common';
import { DeleteMachineDialogStyle as styles } from './DeleteMachineDialogStyle';
import { useAuthenticated } from 'src/hooks/context';
import { TypeCommon, TestCommon } from 'src/common';
import { DEFAULT_DELETE_DIALOG_WIDTH, DEFAULT_BORDER_RADIUS } from 'src/common/machine.common';
import { TypeContext } from 'src/store';
import { IMachineItem } from 'src/types/MachineManagement';
import { GlobalPreservedSpace } from 'src/components';

interface IDeleteMachineDialogProps {
    machine: IMachineItem;
}
const DeleteMachineDialog = (props: IDeleteMachineDialogProps) => {
    const { machine } = props;
    const [appState, dispatch]: TypeContext = useAuthenticated();

    const setCloseDialog = () => dispatch(TypeCommon.setIsOpenDeleteMachineDialog(false));

    const setOpenConfirmDialog = () => dispatch(TypeCommon.setIsOpenDeleteConfirmationDialog(true));
    if ((machine && Object.keys(machine)) || machine?.id) {
        return (
            <Dialog PaperProps={{ style: { width: DEFAULT_DELETE_DIALOG_WIDTH, borderRadius: DEFAULT_BORDER_RADIUS } }} fullWidth={true} open={true}>
                <DialogContent sx={styles.dialogContent} data-testid={TestCommon.DELETE_MACHINE_DIALOG_DATA_TEST_ID}>
                    <Typography variant="h5">{TextCommon.DELETE_MACHINE_TITLE}</Typography>
                    <Box sx={styles.machineItemInfo}>
                        <Box>
                            <img src={IMG_NO_MACHINE_IMAGE} width={130} height={160} />
                        </Box>
                        <Box>
                            <GlobalPreservedSpace>{machine?.id}</GlobalPreservedSpace>
                            <GlobalPreservedSpace>{machine?.name}</GlobalPreservedSpace>
                            <GlobalPreservedSpace>{machine?.model}</GlobalPreservedSpace>
                            <GlobalPreservedSpace>{machine?.customer}</GlobalPreservedSpace>
                            <span>
                                {TextCommon.TIME_ZONE_LABEL}
                                {TextCommon.COLON}
                            </span>
                            <br />
                            <span style={styles.timeZoneValue}>{machine?.timeZone.name}</span>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={styles.dialogAction} data-testid={TestCommon.DELETE_MACHINE_DIALOG_ACTION_DATA_TEST_ID}>
                    <Button variant="outlined" sx={styles.cancelButton} onClick={setCloseDialog} name="cancel">
                        {TextCommon.CANCEL}
                    </Button>
                    <Button variant="contained" sx={styles.deleteButton} onClick={setOpenConfirmDialog} name="delete">
                        {TextCommon.DELETE}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return <></>;
};

export default DeleteMachineDialog;
