/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useTheme } from '@mui/material';
import { Container, Grid } from 'nxg-ui-wrapper';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { SignIn, formFields } from '../../components/SignIn';
import AuthenticatedProvider from '../../store/provider';
import styles from './App.module.css';
import Template from '../../components/Template';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([{ path: '*', Component: Template }]);

const App = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Container maxWidth={false} sx={{ bgcolor: theme.palette.grey[200] }}>
            <Grid className={styles.wrapper} container direction="column">
                <Grid className={styles.bodyWrapper} container direction="column">
                    <Authenticator className={styles.authenticator} formFields={formFields} hideSignUp={true} components={SignIn}>
                        <AuthenticatedProvider>
                            <RouterProvider router={router} />
                        </AuthenticatedProvider>
                    </Authenticator>
                </Grid>
            </Grid>
        </Container>
    );
};

export default App;
