/* eslint-disable @typescript-eslint/no-inferrable-types */
import { BaseCommon, EquipmentCommon, MessageCommon } from '../common';
import { FileSupport } from 'src/common/base.common';
import { FileDb3 } from '../types';

const FILEROOT_LEVEL = 3;
const STRICT_LEVEL = 5;

const validationFile = async (dirHandle: any, name: string = EquipmentCommon.ROOT_FILE): Promise<any> => {
    for await (const entry of dirHandle.values()) {
        if (entry.kind === 'file') {
            const file = (await entry.getFile()) as FileDb3;
            if (file.name === name) {
                return true;
            }
        }
    }
    throw new Error(MessageCommon.NOT_FOUND_EQUIPMENT_FILE);
};

const handleDirectoryEntry = async (dirHandle: any, outputs: FileDb3[], name: string = ''): Promise<any> => {
    for await (const entry of dirHandle.values()) {
        if (entry.kind === 'file') {
            const file = (await entry.getFile()) as FileDb3;
            Object.defineProperty(file, 'path', { value: [name, file.name].join(BaseCommon.CHARACTER_SLASH), writable: false });
            outputs.push(file);
        }

        if (entry.kind === 'directory') {
            const newHandle = await dirHandle.getDirectoryHandle(entry.name, { create: false });
            const path = [name, entry.name].join(BaseCommon.CHARACTER_SLASH);
            await handleDirectoryEntry(newHandle, outputs, path);
        }
    }
};

const filterFileAndFolder = async (outputs: FileDb3[], filterFolder: string[] = [], filterFileExtensions: string[] = []): Promise<FileDb3[]> => {
    let data: FileDb3[] = outputs;

    if (filterFolder && filterFolder.length > 0) {
        data = data.filter((file: FileDb3) => {
            const path = file.path || '';
            const pathSplit = path.split(BaseCommon.CHARACTER_SLASH);
            const isFileRootLevel = pathSplit.length === FILEROOT_LEVEL;

            const isStrictLevel = pathSplit.length === STRICT_LEVEL;
            const isBelongToLogFolder = pathSplit[2] === EquipmentCommon.LOG_FOLDER;
            const isMatchFilterFolder = filterFolder.includes(pathSplit[3]);
            const isInsideLogFolder = isStrictLevel && isBelongToLogFolder && isMatchFilterFolder;
            // check file size
            const hasValidFileSize = file.size >= FileSupport.getMinSizeInBytes() && file.size < FileSupport.getMaxSizeInBytes();

            return (isInsideLogFolder && hasValidFileSize) || isFileRootLevel;
        });
    }

    if (filterFileExtensions && filterFileExtensions.length > 0) {
        data = data.filter((file: FileDb3) => {
            const extension = file.name.substr(file.name.lastIndexOf('.'));
            return filterFileExtensions.includes(extension);
        });
    }
    return data;
};

const readEquipmentId = async (inputs: FileDb3[]): Promise<string> => {
    const equipmentInfoFile = inputs.find((file: FileDb3) => {
        const isMatchFilename = EquipmentCommon.ROOT_FILE === file.name;
        const isFileRootLevel = file.path?.split(BaseCommon.CHARACTER_SLASH).length === FILEROOT_LEVEL;
        return isMatchFilename && isFileRootLevel;
    });

    if (!equipmentInfoFile) {
        throw new Error(MessageCommon.NOT_FOUND_EQUIPMENT_FILE);
    }

    const content = await readContentFile(equipmentInfoFile);

    if (!content) {
        throw new Error(MessageCommon.INVALID_FILE);
    }

    const equipmentInfo = content.match(EquipmentCommon.REGEX_EQUIPMENT_ID);

    if (!equipmentInfo || !Array.isArray(equipmentInfo) || equipmentInfo.length !== 2) {
        throw new Error(MessageCommon.NOT_GET_EQUIPMENT_ID);
    }

    return equipmentInfo[1];
};

const readContentFile = async (input: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (!input) {
            reject(MessageCommon.INVALID_FILE);
        }

        const reader = new FileReader();
        reader.readAsText(input, 'UTF-8');
        reader.onload = function (evt: any): void {
            resolve(evt.target.result);
        };
        reader.onerror = function (evt: any): void {
            reject(JSON.stringify(evt));
        };
    });
};

export { readContentFile, readEquipmentId, validationFile, filterFileAndFolder, handleDirectoryEntry };
