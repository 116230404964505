import { useCallback, useMemo, useState } from 'react';
import format from 'string-format';
import {
    FileDb3,
    ICompletedScanningType,
    IFetchS3Type,
    INotify,
    INotifyType,
    ENotifyType,
    ITriggerLoadingType,
    S3StatusBucket,
    IMachineInformation,
} from 'src/types';
import { BaseCommon, MessageCommon, TypeCommon, MachineCommon } from '../../../common';
import { useAuthenticated } from '../../../hooks/context';
import { TypeContext } from '../../../store';
import { ExtLogicS3 } from '../../../business';
import useTimer from 'src/hooks/useTimer';
import { IMainPageHook } from '../type';
import { readEquipmentId } from 'src/utils';
import s3Client from 'src/custom/amplifys3.client';

const BACKDROP_CLICK = 'backdropClick';
const UNLOAD_RETURN_VALUE = 'leave';
const useMainPage = (): IMainPageHook => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const {
        s3File,
        equipmentId,
        uploadingFile,
        isScanning,
        isUploaded,
        isUploading,
        isShowPopup,
        isError,
        errorMessage,
        errorReason,
        intervalId,
        auto,
        timer,
        isOtherProcessing,
        isOpenBackDoor,
    } = appState;
    const { handleRefresh, handleError, clearTimer } = useTimer();
    const [intervalIdTmp, setIntervalIdTmp] = useState<undefined | NodeJS.Timer>();

    const handleConfirmUpload = async (): Promise<void> => {
        try {
            const isReadyToUpload = await validateBeforeUpload();

            if (!isReadyToUpload) {
                return;
            }
            await ExtLogicS3.updateBlockingFlag(true);
            dispatch(TypeCommon.setConfirmUploadType());
            await ExtLogicS3.calculateUploadingProgress(uploadingFile, equipmentId, dispatch);
        } catch (err: any) {
            console.error('ERROR::UPLOADING_S3:: ', err);
            handleError({
                message: format(MessageCommon.UPLOADED_THROW_ERROR, err.message),
                otherMessage: err.message,
            });
        }
    };

    const instanceOfMachineInfo = (object: any): object is IMachineInformation => {
        return MachineCommon.ID_KEY_NAME in object;
    };

    const getMachineInformation = async (machineId: string) => {
        try {
            const machineInformation = await MachineCommon.QueryAgent.query<IMachineInformation>({
                path: MachineCommon.GET_MACHINE_INFO_PATH,
                config: {
                    pathParams: {
                        id: machineId,
                    },
                },
            });
            if (machineInformation) {
                if (!instanceOfMachineInfo(machineInformation)) {
                    throw { status: BaseCommon.HTTP_STATUS_CODE_NOT_FOUND };
                }
            }
        } catch (error: any) {
            if (error?.status === BaseCommon.HTTP_STATUS_CODE_NOT_FOUND) {
                throw new Error(format(MessageCommon.MESSAGE_ERROR_NOT_FOUND_MACHINE, machineId));
            } else {
                throw new Error(error?.data?.detail);
            }
        }
    };

    const validateBeforeUpload = async (): Promise<boolean> => {
        const blocking = await ExtLogicS3.fetchBlockingFromS3();
        const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();

        const isEmptyAllBuckets = (data: S3StatusBucket[]): boolean => {
            const fileCount = data.reduce((total: number, bucket: S3StatusBucket) => total + bucket.totalSize, 0);
            return fileCount === BaseCommon.DEFAULT_FILE_IN_BUCKET_COUNT;
        };
        const isReadyToUpload = isEmptyAllBuckets([s3Data, blocking]);
        if (!isReadyToUpload) {
            const payload: INotifyType = {
                notify: {
                    message: MessageCommon.MESSAGE_ERROR_ALREADY_BLOCKING_FLAG,
                    isOpen: true,
                    type: ENotifyType.ERROR,
                },
            };
            dispatch(TypeCommon.setNotifyType(payload));
        }
        return isReadyToUpload;
    };

    // Function Business
    const handleChangeAuto = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        if (!checked) {
            clearTimer();
            dispatch(TypeCommon.setTimer(BaseCommon.APP_INTERVAL_TIME_SECOND));
        } else {
            handleRefresh();
        }
        dispatch(TypeCommon.setAuto(checked));
    };

    const handleLoadedFiles = async (fs: FileDb3[]): Promise<void> => {
        try {
            const equipmentId = await readEquipmentId(fs);
            await getMachineInformation(equipmentId);
            const loadedFiles = ExtLogicS3.buildResourceEntry(fs);
            const payload: ICompletedScanningType = {
                equipmentId,
                uploadingFile: loadedFiles,
            };
            if (ExtLogicS3.isEmptyLocalFiles(loadedFiles)) {
                payload.isUploaded = true;
            }
            setTimeout(() => {
                dispatch(TypeCommon.setCompletedScanningType(payload));
            }, BaseCommon.APP_SCANNING_DELAY_MILLISECOND);
        } catch (err: any) {
            console.error('ERROR::LOAD_LOCAL_FILE:: ', err);
            handleError(err);
        }
    };

    const handleScanning = (state: boolean): void => {
        const payload: ITriggerLoadingType = {
            isScanning: state,
        };
        dispatch(TypeCommon.setTriggerLoadingType(payload));
    };

    const notifyWhenUploadCompleted = (): void => {
        if (!ExtLogicS3.isCompletedProgress(uploadingFile)) {
            return;
        }

        let notify: INotify = {
            message: MessageCommon.UPLOADED_SUCCESS_ALL_FILES,
            isOpen: true,
            type: ENotifyType.SUCCESS,
        };

        if (ExtLogicS3.isErrorAllFilesUploaded(uploadingFile)) {
            notify = {
                message: MessageCommon.UPLOADED_ERROR_ALL_FILES,
                isOpen: true,
                type: ENotifyType.ERROR,
            };
        } else if (ExtLogicS3.isErrorAnyFileUploaded(uploadingFile)) {
            notify = {
                message: MessageCommon.UPLOADED_SUCCESS_SOME_FILES,
                isOpen: true,
                type: ENotifyType.SUCCESS,
            };
        }
        const payload: INotifyType = { notify };
        dispatch(TypeCommon.setCompletedUploadType(payload));
        handleRefresh();
    };

    const handleClickRefresh = async (): Promise<void> => {
        try {
            const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();
            const s3Blocking = await ExtLogicS3.fetchBlockingFromS3();
            const payload: IFetchS3Type = {
                s3File: s3Data,
                isOtherProcessing: s3Blocking.totalSize > BaseCommon.DEFAULT_FILE_IN_BUCKET_COUNT,
            };
            dispatch(TypeCommon.setFetchS3Type(payload));
        } catch (error: any) {
            console.log('ERROR::CLICK_FETCH_S3:: ', error);
            handleError({
                ...error,
                message: format(MessageCommon.S3_ERROR, error.message),
                otherMessage: error.message,
            });
        }
    };

    const handleClose = (): void => {
        dispatch(TypeCommon.setClearErrorType());
    };

    // calculate the conditions USE_MEMO
    const isBackendProcessing = useMemo((): boolean => {
        return s3File?.objects?.length > 0;
    }, [s3File]);

    const handleCancelUpload = useCallback((event?: object, reason?: string): void => {
        if (reason === BACKDROP_CLICK) {
            return;
        }
        dispatch(TypeCommon.setCancelUploadType());
    }, []);

    const onUnload = (event: BeforeUnloadEvent): void => {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.returnValue = UNLOAD_RETURN_VALUE;
    };

    const loadRdsConfigurationBySession = (): void => {
        const config = ExtLogicS3.initialConfigToSession(BaseCommon.ALL_S3_CONFIGS);
        s3Client.updateConfiguration(config);
    };

    return {
        handleRefresh,
        clearTimer,
        notifyWhenUploadCompleted,
        uploadingFile,
        timer,
        isBackendProcessing,
        isUploading,
        handleScanning,
        handleError,
        handleLoadedFiles,
        s3File,
        auto,
        handleClickRefresh,
        handleChangeAuto,
        isError,
        errorMessage,
        isScanning,
        handleClose,
        isShowPopup,
        isUploaded,
        errorReason,
        equipmentId,
        handleCancelUpload,
        handleConfirmUpload,
        intervalId,
        isOtherProcessing,
        intervalIdTmp,
        setIntervalIdTmp,
        onUnload,
        loadRdsConfigurationBySession,
        isOpenBackDoor,
        getMachineInformation,
    };
};

export default useMainPage;
