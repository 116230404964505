import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Box, Button, InputBase, Select, MenuItem } from 'nxg-ui-wrapper';
import InputField from '../InputField';
import TextCommon from 'src/common/text.common';
import { UpdateMachineDialogStyle as styles } from './UpdateMachineDialogStyle';
import { TypeCommon, TestCommon } from 'src/common';
import { TypeContext } from 'src/store';
import { useAuthenticated } from 'src/hooks/context';
import { TIME_ZONE_LIST, TIME_ZONE_KEY_NAME } from 'src/common/machine.common';
import { IMachineItem, TimeZone } from 'src/types/MachineManagement';
import { IMG_NO_MACHINE_IMAGE } from 'src/assets';
import { colors } from 'src/global/style.object';
import { PlaceHolder } from 'src/components';

const DEFAULT_MODEL_PLACEHOLDER = <Typography color={colors.inputPlaceholder}>{TextCommon.MODEL_PLACEHOLDER}</Typography>;
const DEFAULT_TIMEZONE_PLACEHOLDER = <Typography color={colors.inputPlaceholder}>{TextCommon.TIME_ZONE_PLACEHOLDER}</Typography>;
interface IUpdateMachineDialogProps {
    machine: IMachineItem;
    setMachine: React.Dispatch<React.SetStateAction<IMachineItem>>;
    modelList: string[];
    onSave: (machine: IMachineItem) => void;
}
const UpdateMachineDialog = (props: IUpdateMachineDialogProps) => {
    const { machine, setMachine, modelList, onSave } = props;
    const [appState, dispatch]: TypeContext = useAuthenticated();

    const setCloseDialog = () => dispatch(TypeCommon.setIsOpenUpdateMachineDialog(false));

    const handleOnChangeMachineObj = (e: any) => {
        const returnMachineCallBack = (machine: IMachineItem) => {
            const { name, value } = e.target;
            if (name === TIME_ZONE_KEY_NAME) {
                machine[name] = TIME_ZONE_LIST.find((timezone: TimeZone) => timezone.index === value);
            } else {
                machine[name] = value;
            }
            return machine;
        };
        setMachine(previousMachine => returnMachineCallBack({ ...previousMachine }));
    };

    const renderModelValue = () => {
        if (machine?.model) {
            return machine?.model;
        }
        return <PlaceHolder text={TextCommon.MODEL_PLACEHOLDER} />;
    };

    const renderTimeZoneValue = () => {
        if (machine?.timeZone) {
            return machine?.timeZone.name;
        }
        return <PlaceHolder text={TextCommon.TIME_ZONE_PLACEHOLDER} />;
    };

    if ((machine && Object.keys(machine)) || machine?.id) {
        return (
            <Dialog maxWidth="md" PaperProps={{ style: { width: 'fit-content', borderRadius: '0' } }} fullWidth={true} open={true}>
                <DialogContent sx={styles.dialogContent} data-testid={TestCommon.UPDATE_MACHINE_DIALOG_BODY_DATA_TEST_ID}>
                    <Typography variant="h5">{TextCommon.UPDATE_MACHINE_TITLE}</Typography>
                    <Box sx={styles.formWrapper}>
                        <InputField required label={TextCommon.MACHINE_ID_LABEL}>
                            <InputBase
                                sx={styles.textInput}
                                type="text"
                                placeholder={TextCommon.MACHINE_ID_PLACEHOLDER}
                                disabled
                                value={machine?.id}
                                name="id"
                                onChange={e => handleOnChangeMachineObj(e)}
                            />
                        </InputField>
                        <InputField label={TextCommon.MACHINE_NAME_LABEL}>
                            <InputBase
                                sx={styles.textInput}
                                type="text"
                                placeholder={TextCommon.MACHINE_NAME_PLACEHOLDER}
                                value={machine?.name}
                                name="name"
                                onChange={e => handleOnChangeMachineObj(e)}
                            />
                        </InputField>
                        <InputField required label={TextCommon.MODEL_LABEL}>
                            <Select
                                renderValue={renderModelValue}
                                displayEmpty
                                sx={styles.selectField}
                                variant="standard"
                                disableUnderline
                                name="model"
                                value={machine?.model}
                                onChange={e => handleOnChangeMachineObj(e)}
                                data-testid={TestCommon.MODEL_SELECT_BOX_DATA_TEST_ID}
                            >
                                {modelList?.map((item, idx) => (
                                    <MenuItem key={idx} value={item} data-testid={TestCommon.MODEL_ITEM_DATA_TEST_ID}>
                                        <img src={IMG_NO_MACHINE_IMAGE} width={45} height={48} />
                                        <Typography marginLeft={2}>{item}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </InputField>
                        <InputField label={TextCommon.CUSTOMER_NAME_LABEL}>
                            <InputBase
                                type="text"
                                sx={styles.textInput}
                                placeholder={TextCommon.CUSTOMER_NAME_PLACEHOLDER}
                                value={machine?.customer}
                                name="customer"
                                onChange={e => handleOnChangeMachineObj(e)}
                            />
                        </InputField>
                        <InputField required label={TextCommon.TIME_ZONE_LABEL}>
                            <Select
                                renderValue={renderTimeZoneValue}
                                displayEmpty
                                sx={styles.selectField}
                                variant="standard"
                                disableUnderline
                                name="timeZone"
                                value={machine?.timeZone?.index}
                                onChange={e => handleOnChangeMachineObj(e)}
                                data-testid={TestCommon.TIMEZONE_SELECT_BOX_DATA_TEST_ID}
                            >
                                {TIME_ZONE_LIST?.map((item, idx) => (
                                    <MenuItem key={idx} value={item.index}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </InputField>
                    </Box>
                </DialogContent>
                <DialogActions sx={styles.dialogAction} data-testid={TestCommon.UPDATE_MACHINE_DIALOG_ACTION_DATA_TEST_ID}>
                    <Button name="cancel" variant="outlined" sx={styles.cancelButton} onClick={setCloseDialog}>
                        {TextCommon.CANCEL}
                    </Button>
                    <Button name="save" variant="contained" sx={styles.saveButton} onClick={() => onSave(machine)}>
                        {TextCommon.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return <></>;
};

export default React.memo(UpdateMachineDialog);
