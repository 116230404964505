import { colors } from 'src/global/style.object';

export const BackDoorStyle = {
    dialog: {
        padding: '5px',
    },
    dialogContent: {
        color: colors.blackText,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    contentTitle: {
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
    },
    circleProgress: {
        position: 'relative',
        right: '380px',
    },
    input: {
        width: '100%',
    },
};
