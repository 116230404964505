import { LinearProgressProps, LinearProgressPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Box, LinearProgress as MuiLinearProgress, Typography } from 'nxg-ui-wrapper';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { SystemStyleObject } from '@mui/system';

import styles from './LinearProgress.module.css';
import { BaseCommon, TestCommon } from '../../common';
import { UPLOAD_COMPLETE, CHECKED } from './icon';

interface LinearWithValueLabelProps {
    color?: OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit', LinearProgressPropsColorOverrides>;
    progress: number;
    skip?: boolean;
    sx?: SystemStyleObject;
    fontWeight?: string | number;
    status?: boolean;
    isTotalProgress?: boolean;
}

interface LinearProgressWithLabelProps extends LinearProgressProps {
    value: number;
    skip: boolean | string;
    fontWeight?: string | number;
    status?: boolean;
    isTotalProgress?: boolean;
}

const LinearProgressWithLabel = (props: LinearProgressWithLabelProps): JSX.Element => {
    const renderStatusLabel = () => {
        if (Math.round(props.value) !== BaseCommon.COMPLETED_PERCENTAGE_NUM || props.isTotalProgress) {
            return (
                <Typography variant="body2" fontWeight={props.fontWeight}>
                    {props.value === BaseCommon.COMPLETED_PERCENTAGE_NUM && !props.skip ? (
                        <TaskAltIcon color="success" />
                    ) : (
                        `${Math.round(props.value)}${BaseCommon.CHARACTER_PERCENTAGE}`
                    )}
                </Typography>
            );
        }
        return !props.status ? CHECKED : UPLOAD_COMPLETE;
    };
    return (
        <Box className={styles.wrapper}>
            <Box className={styles.spacing}>
                <MuiLinearProgress className={styles['default-style']} variant="determinate" value={props.value} color={props.color} sx={props.sx} />
            </Box>
            <Box className={styles.text} data-testid={TestCommon.PROGRESS_LABEL_DATA_TEST_ID}>
                {renderStatusLabel()}
            </Box>
        </Box>
    );
};

const LinearProgress = ({ color, progress, skip = true, sx, fontWeight, status, isTotalProgress }: LinearWithValueLabelProps): JSX.Element => {
    return (
        <Box className={styles.text}>
            <LinearProgressWithLabel
                value={progress}
                color={color}
                skip={skip}
                sx={sx}
                fontWeight={fontWeight}
                status={status}
                isTotalProgress={isTotalProgress}
            />
        </Box>
    );
};

export default LinearProgress;
