const baseButton = {
    textTransform: 'capitalize',
    padding: '6px 30px',
};
export const DeleteConfirmationDialogStyle = {
    cancelButton: {
        ...baseButton,
        color: '#B40000',
        border: '1px solid #B40000',
        '&:hover': {
            border: '1px solid #B40000',
            opacity: 0.8,
            background: '#ffffff',
        },
    },
    deleteButton: {
        ...baseButton,
        background: '#B40000',
        '&:hover': {
            background: '#B40000',
            opacity: 0.8,
        },
    },
    dialogContent: {
        padding: '11px 22px 11px 22px',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        '&>p': {
            marginTop: '15px',
        },
    },
    dialogAction: { padding: '0px 22px 11px 22px' },
};
