/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { TypeContext } from '../../store/provider';
import { useAuthenticated } from '../../hooks/context';
import { Box, Backdrop, CircularProgress } from 'nxg-ui-wrapper';
import styles from './Template.module.css';
import { MainPage, MachineManagement, ErrorPage } from 'src/pages';
import { Routes, Route } from 'react-router-dom';
import { ErrorNotification, BackDoor, MainLayout } from 'src/components';
import { RouteCommon, TypeCommon } from 'src/common';
import { colors } from 'src/global/style.object';

const Template = (): JSX.Element => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const handleCloseMenu = (): void => {
        if (appState?.isShowMenu) {
            dispatch(TypeCommon.setIsShowMenu(false));
        }
    };
    const handleCloseError = () => {
        dispatch(TypeCommon.setClearErrorType());
    };

    return (
        <Box onClick={handleCloseMenu} className={styles['template-wrapper']}>
            <MainLayout>
                <Routes>
                    <Route path={RouteCommon.DEFAULT_SCREEN} element={<MainPage />} />
                    <Route path={RouteCommon.DATA_IMPORTER_SCREEN} element={<MainPage />} />
                    <Route path={RouteCommon.MACHINE_MANAGEMENT_SCREEN} element={<MachineManagement />} />
                    <Route path={RouteCommon.ERROR_SCREEN} element={<ErrorPage />} />
                </Routes>
            </MainLayout>
            <ErrorNotification open={appState.isError} message={appState.errorMessage} onClose={handleCloseError} />
            <Backdrop open={appState.isOpenBackDrop} sx={{ zIndex: 100000 }}>
                <CircularProgress sx={{ color: colors.white }} />
            </Backdrop>
            {appState.isOpenBackDoor && <BackDoor />}
        </Box>
    );
};

export default Template;
