import React, { useState } from 'react';
import { DialogContent, DialogActions, Dialog, DialogTitle, Box, Typography, Button, CircularProgress } from 'nxg-ui-wrapper';
import { TextCommon, TypeCommon, TestCommon } from 'src/common';
import { BackDoorStyle as styles } from './BackDoorStyle';
import { useAuthenticated } from 'src/hooks/context';
import { TypeContext } from 'src/store';
import { BucketType, IBucketBackDoorProperties } from 'src/types';
import useBackdoor from 'src/hooks/useBackdoor';
import useTimer from 'src/hooks/useTimer';
import BucketUrlField from './BucketUrlField';

const BUCKET_FIELDS_PROPS: IBucketBackDoorProperties[] = [
    {
        type: BucketType.SQLITE,
        inputLabel: TextCommon.BUCKET_SQLITE_LABEL,
        errorMessage: null,
        dataTest: TestCommon.SQLITE_BUCKET_DATA_TEST_ID,
        url: null,
    },
    {
        type: BucketType.BLOCKING,
        inputLabel: TextCommon.BUCKET_BLOCKING_LABEL,
        errorMessage: null,
        dataTest: TestCommon.BLOCKING_BUCKET_DATA_TEST_ID,
        url: null,
    },
];

const BackDoor = () => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const [bucketUrls, setBucketUrls] = useState<any>(JSON.parse(JSON.stringify(BUCKET_FIELDS_PROPS)));
    const [openLoading, setOpenLoading] = useState<boolean>(false);
    const [setOpen] = useBackdoor();
    const { handleRefresh } = useTimer();

    const handleOnclose = () => {
        dispatch(TypeCommon.setIsOpenBackDoor(false));
    };

    const handleOnConfirm = async () => {
        setOpenLoading(true);
        await setOpen(handleRefresh, bucketUrls);
        setOpenLoading(false);
        const isValidUrlArray = bucketUrls.find((url: IBucketBackDoorProperties) => url.errorMessage !== null);
        if (!isValidUrlArray) {
            dispatch(TypeCommon.setIsOpenBackDoor(false));
        }
    };

    return (
        <Dialog open={true} maxWidth="sm" fullWidth PaperProps={{ style: styles.dialog }}>
            <DialogTitle>{TextCommon.BACKDOOR_CONTENT_TITLE}</DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                {bucketUrls.map((item: any, index: number) => (
                    <Box key={index}>
                        <BucketUrlField
                            errorMessage={item.errorMessage}
                            type={item.type}
                            inputLabel={item.inputLabel}
                            bucketUrls={bucketUrls}
                            setBucketUrls={setBucketUrls}
                            dataTest={item.dataTest}
                        />
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                {openLoading && <CircularProgress sx={styles.circleProgress} size={15} />}
                <Button onClick={handleOnclose}>{TextCommon.CLOSE}</Button>
                <Button data-testid={TestCommon.CONFIRM_BUTTON_DATA_TEST_ID} onClick={handleOnConfirm}>
                    {TextCommon.CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BackDoor;
