/* eslint-disable no-var */
import {
    ICompletedScanningType,
    IFetchS3Type,
    INotifyType,
    IThrowErrorUploadType,
    ITriggerErrorType,
    ITriggerLoadingType,
    IUploadType,
    Notify,
    FileOfBucket,
    S3StatusBucket,
    BucketType,
} from '../types';
import { AppAction } from '.';
import { AWSS3Config, AmplifyS3Config } from '../custom/type';
import { ExtLogicS3 } from '../business';
import { APP_SQLITE_CONFIG, ALL_S3_CONFIGS, DEFAULT_BUCKET_INFORMATION } from '../common/base.common';
import { BaseCommon, MessageCommon } from 'src/common';

interface AuthenticatedState {
    s3File: S3StatusBucket;
    equipmentId: string;
    uploadingFile: FileOfBucket;
    isScanning: boolean;
    isUploading: boolean;
    isUploaded: boolean;
    isShowPopup: boolean;
    errorReason: string;
    isError: boolean;
    notify: Notify;
    errorMessage: string;
    isShowBackdoor: boolean;
    sqliteBucket: AWSS3Config;
    isOtherProcessing: boolean;
    pageId: string;
    isShowMenu: boolean;
    intervalId: undefined | NodeJS.Timer;
    auto: boolean;
    timer: number;
    isOpenBackDoor: boolean;
    isOpenCreateMachineDialog: boolean;
    isOpenUpdateMachineDialog: boolean;
    isOpenDeleteMachineDialog: boolean;
    isOpenDeletConfirmationDialog: boolean;
    isOpenBackDrop: boolean;
    isOpenDeleteMachineLogDialog: boolean;
    isOpenDeletMachineLogConfirmationDialog: boolean;
}

interface AuthenticatedPayload {
    type: AppAction;
    payload?: any;
}

const initialState: AuthenticatedState = {
    s3File: {} as S3StatusBucket,
    uploadingFile: DEFAULT_BUCKET_INFORMATION,
    equipmentId: '',
    isScanning: false,
    isUploading: false,
    isUploaded: false,
    isShowPopup: false,
    errorReason: '',
    isError: false,
    notify: new Notify(),
    errorMessage: '',
    isShowBackdoor: false,
    sqliteBucket: APP_SQLITE_CONFIG.AWSS3,
    isOtherProcessing: false,
    pageId: '1',
    isShowMenu: false,
    intervalId: undefined,
    auto: true,
    timer: BaseCommon.APP_INTERVAL_TIME_SECOND,
    isOpenBackDoor: false,
    isOpenCreateMachineDialog: false,
    isOpenUpdateMachineDialog: false,
    isOpenDeleteMachineDialog: false,
    isOpenDeletConfirmationDialog: false,
    isOpenBackDrop: false,
    isOpenDeleteMachineLogDialog: false,
    isOpenDeletMachineLogConfirmationDialog: false,
};

const reducer = (prevState: AuthenticatedState, action: AuthenticatedPayload): AuthenticatedState => {
    switch (action.type) {
        case AppAction.FETCH_S3:
            return {
                ...prevState,
                s3File: (action.payload as IFetchS3Type).s3File,
                isOtherProcessing: (action.payload as IFetchS3Type).isOtherProcessing,
            };

        case AppAction.CANCEL_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploaded: false,
                isShowPopup: false,
                uploadingFile: DEFAULT_BUCKET_INFORMATION,
            };

        case AppAction.CONFIRM_UPLOAD:
            return {
                ...prevState,
                isScanning: true,
                isUploading: true,
                isUploaded: false,
                errorReason: '',
            };

        case AppAction.COMPLETED_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploading: false,
                isUploaded: true,
                errorReason: '',
                notify: {
                    ...prevState.notify,
                    ...(action.payload as INotifyType).notify,
                },
            };

        case AppAction.THROW_ERROR_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploaded: false,
                uploadingFile: (action.payload as IThrowErrorUploadType).uploadingFile,
            };

        case AppAction.COMPLETED_FILES_SCANNING:
            return {
                ...prevState,
                equipmentId: (action.payload as ICompletedScanningType).equipmentId,
                uploadingFile: (action.payload as ICompletedScanningType).uploadingFile,
                isUploaded: (action.payload as ICompletedScanningType).isUploaded || false,
                isScanning: false,
                isShowPopup: true,
            };

        case AppAction.TRIGGER_ERROR:
            return {
                ...prevState,
                isError: !!(action.payload as ITriggerErrorType).message,
                errorMessage: (action.payload as ITriggerErrorType).message,
                errorReason: (action.payload as ITriggerErrorType).otherMessage || '',
                isScanning: false,
            };

        case AppAction.CLEAR_ERROR:
            return {
                ...prevState,
                isError: false,
            };

        case AppAction.TRIGGER_LOADING:
            return {
                ...prevState,
                isScanning: (action.payload as ITriggerLoadingType).isScanning,
            };

        case AppAction.SET_UPLOAD:
            return {
                ...prevState,
                uploadingFile: (action.payload as IUploadType).uploadingFile,
            };

        case AppAction.SET_NOTIFY:
            return {
                ...prevState,
                notify: {
                    ...prevState.notify,
                    ...(action.payload as INotifyType).notify,
                },
            };
        case AppAction.SET_SQLITE_BUCKET:
            return {
                ...prevState,
                sqliteBucket: loadSqLiteConfiguration(action),
            };

        case AppAction.UPDATE_PAGE_ID:
            return {
                ...prevState,
                pageId: action.payload,
            };
        case AppAction.SET_IS_SHOW_MENU:
            return {
                ...prevState,
                isShowMenu: action.payload,
            };
        case AppAction.SET_INTERVAL_ID: {
            return {
                ...prevState,
                intervalId: action.payload,
            };
        }

        case AppAction.SET_AUTO:
            return {
                ...prevState,
                auto: action.payload,
            };
        case AppAction.SET_TIMER:
            return {
                ...prevState,
                timer: action.payload,
            };
        case AppAction.SET_IS_OPEN_BACKDOOR:
            return {
                ...prevState,
                isOpenBackDoor: action.payload,
            };
        case AppAction.SET_IS_OPEN_CREATE_MACHINE_DIALOG:
            return {
                ...prevState,
                isOpenCreateMachineDialog: action.payload,
            };
        case AppAction.SET_IS_OPEN_UPDATE_MACHINE_DIALOG:
            return {
                ...prevState,
                isOpenUpdateMachineDialog: action.payload,
            };
        case AppAction.SET_IS_OPEN_DELETE_MACHINE_DIALOG:
            return {
                ...prevState,
                isOpenDeleteMachineDialog: action.payload,
            };
        case AppAction.SET_IS_OPEN_DELETE_CONFIRMATION_DIALOG:
            return {
                ...prevState,
                isOpenDeletConfirmationDialog: action.payload,
            };
        case AppAction.SET_IS_OPEN_BACKDROP:
            return {
                ...prevState,
                isOpenBackDrop: action.payload,
            };
        case AppAction.SET_ERROR_MESSAGE:
            return {
                ...prevState,
                isError: Boolean(action.payload),
                errorMessage: action.payload,
            };
        case AppAction.SET_IS_OPEN_DELETE_MACHINE_LOG_DIALOG:
            return {
                ...prevState,
                isOpenDeleteMachineLogDialog: action.payload,
            };
        case AppAction.SET_IS_OPEN_DELETE_MACHINE_LOG_CONFIRMATION_DIALOG:
            return {
                ...prevState,
                isOpenDeletMachineLogConfirmationDialog: action.payload,
            };

        default:
            throw new Error(MessageCommon.INVALID_ACTION_ERROR);
    }
};

const getSqliteBucketFromList = (bucketConfigs: AmplifyS3Config<BucketType>[]): AmplifyS3Config<BucketType> | undefined => {
    const sqliteConfig = bucketConfigs.find((itm: AmplifyS3Config<BucketType>) => itm.name === BucketType.SQLITE);
    return sqliteConfig;
};

const loadSqLiteConfiguration = (action?: AuthenticatedPayload): AWSS3Config => {
    const configs = ExtLogicS3.initialConfigToSession(ALL_S3_CONFIGS);
    const config = getSqliteBucketFromList(configs) as AmplifyS3Config<BucketType>;
    const currentBucketName = config.AWSS3.bucket;
    const currentRegion = config.AWSS3.region;
    // get bucket from session storage
    const retrieveBucket = getSqliteBucketFromList(action?.payload);
    const bucketName = retrieveBucket?.AWSS3.bucket?.trim() || currentBucketName;
    const region = retrieveBucket?.AWSS3.region?.trim() || currentRegion;
    const bucket: AWSS3Config = {
        bucket: bucketName,
        region,
    };
    return bucket;
};

export { initialState };
export type { AuthenticatedState, AuthenticatedPayload };
export default reducer;
