import React from 'react';
import { Box, Typography, TextField } from 'nxg-ui-wrapper';
import { BackDoorStyle as styles } from './BackDoorStyle';
import { BucketType, IBucketBackDoorProperties } from 'src/types';

interface BucketUrlFieldProps {
    inputLabel: string;
    type: BucketType;
    bucketUrls: IBucketBackDoorProperties[];
    errorMessage: string | null;
    setBucketUrls: React.Dispatch<any>;
    dataTest: string;
}
const BucketUrlField = (props: BucketUrlFieldProps) => {
    const { inputLabel, type, bucketUrls, errorMessage, setBucketUrls, dataTest } = props;

    const handleOnChange = (event: any) => {
        let bucketUrlsTmp: IBucketBackDoorProperties[];
        try {
            bucketUrlsTmp = JSON.parse(JSON.stringify(bucketUrls));
            bucketUrlsTmp.forEach((urlObj: IBucketBackDoorProperties) => {
                if (urlObj.type === type) {
                    urlObj.url = event.target.value;
                    urlObj.errorMessage = null;
                    setBucketUrls(bucketUrlsTmp);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Box>
            <Typography>{inputLabel}</Typography>
            <TextField
                data-testid={dataTest}
                variant="outlined"
                sx={styles.input}
                onChange={handleOnChange}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
            />
        </Box>
    );
};
export default BucketUrlField;
