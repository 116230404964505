import React, { memo, useEffect, useState } from 'react';
import {
    CreateMachineDialog,
    UpdateMachineDialog,
    DeleteMachineDialog,
    DeleteConfirmationDialog,
    CreateButton,
    DeleteMachineLogDialog,
    DeleteMachineLogConfirmationDialog,
} from 'src/components';
import { useAuthenticated } from 'src/hooks/context';
import { TypeContext } from 'src/store';
import useMachineList from './hooks/useMachineList';
import { MachineManagementStyle as styles } from './MachineManagementStyle';
import style from './MachineManagementStyle.module.css';
import { Box, MUITable, ITableColumn } from 'nxg-ui-wrapper';
import { BaseCommon, MachineCommon, TextCommon } from 'src/common';
import { ICSSWordBreak, IDeleteMachineLogItem } from 'src/types/MachineManagement';

const TABLE_CONTAINER_STYLE = { width: '100%', overflowX: 'auto', maxHeight: '80vh', ...styles.scrollbar };

const MachineManagement = (): JSX.Element => {
    const [appState]: TypeContext = useAuthenticated();
    const [dateTime, setDateTime] = useState<string>(BaseCommon.EMPTY_STRING);
    const [deleteMode, setDeleteMode] = useState<string>(BaseCommon.DELETE_MODE_BEFORE);
    const {
        modelList,
        handleCreateMachine,
        handleDeleteMachine,
        setCreatedMachineObject,
        createdMachineObject,
        deletedMachineObject,
        updatedMachineObject,
        setUpdatedMachineObject,
        handleUpdateMachine,
        newestUpdateMachine,
        machineCreationValidationError,
        machineInfoRows,
        deletedMachineLogObject,
        handleDeleteMachineLog,
    } = useMachineList();

    const [deletedMachineLog, setDeletedMachineLog] = useState<IDeleteMachineLogItem>(deletedMachineLogObject);

    const handleSaveData = data => {
        setDateTime(data.dateTime);
        setDeleteMode(data.mode);
    };

    useEffect(() => {
        const deleteMachineLogItem: IDeleteMachineLogItem = {
            dateTime,
            mode: deleteMode,
            id: deletedMachineLogObject.id,
            timeZone: deletedMachineLogObject.timeZone,
        };
        setDeletedMachineLog(deleteMachineLogItem);
    }, [dateTime, deleteMode, deletedMachineLogObject]);

    const {
        isOpenDeleteMachineDialog,
        isOpenUpdateMachineDialog,
        isOpenDeletConfirmationDialog,
        isOpenCreateMachineDialog,
        isOpenDeleteMachineLogDialog,
        isOpenDeletMachineLogConfirmationDialog,
    } = appState;
    return (
        <div>
            <div>
                <Box sx={styles.machineList}>
                    <MUITable
                        tableComponentProps={{
                            rows: machineInfoRows(),
                            columns: MachineCommon.MACHINE_LIST_TABLE_COLUMNS,
                            isSortingMode: true,
                            header: TextCommon.MACHINE_MANAGEMENT_HEADING,
                            searchDataInColumns: MachineCommon.MACHINE_LIST_TABLE_COLUMNS.map((col: ITableColumn) => col.key),
                            customHeaderRight: <CreateButton />,
                            selectedRowKeys: [newestUpdateMachine.id],
                        }}
                        tableSortLabelProps={{
                            hideSortIcon: true,
                            sx: {
                                wordBreak: ICSSWordBreak.keepAll,
                            },
                        }}
                        tableContainerProps={{ sx: TABLE_CONTAINER_STYLE }}
                        tableRowProps={{ sx: styles.tableRow }}
                        tableProps={{ stickyHeader: true, className: style.tableHeadStyle }}
                    />
                </Box>
            </div>
            {isOpenDeleteMachineLogDialog && <DeleteMachineLogDialog machineLogToDelete={deletedMachineLog} saveData={handleSaveData} />}
            {isOpenDeletMachineLogConfirmationDialog && (
                <DeleteMachineLogConfirmationDialog onDelete={handleDeleteMachineLog} params={deletedMachineLog} />
            )}
            {isOpenCreateMachineDialog && (
                <CreateMachineDialog
                    modelList={modelList}
                    onCreate={handleCreateMachine}
                    setMachine={setCreatedMachineObject}
                    machine={createdMachineObject}
                    validationError={machineCreationValidationError}
                />
            )}
            {isOpenUpdateMachineDialog && (
                <UpdateMachineDialog
                    machine={updatedMachineObject}
                    setMachine={setUpdatedMachineObject}
                    modelList={modelList}
                    onSave={handleUpdateMachine}
                />
            )}
            {isOpenDeleteMachineDialog && <DeleteMachineDialog machine={deletedMachineObject} />}
            {isOpenDeletConfirmationDialog && <DeleteConfirmationDialog onDelete={handleDeleteMachine} machine={deletedMachineObject} />}
        </div>
    );
};

export default memo(MachineManagement);
