import React from 'react';
import { DialogContent, DialogContentText, DialogActions, Typography, Dialog, Button } from 'nxg-ui-wrapper';
import { TransitionProps } from '@mui/material/transitions';
import { TextCommon, TestCommon } from 'src/common';
import styles from './ErrorNotification.module.css';
import { colors } from '../../global/style.object';
import { IMG_FILE_ERROR } from './icon';
import { GlobalPreservedSpace } from 'src/components';

export interface ErrorNotificationProps {
    open: boolean;
    message: string;
    transition?: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>;
    onClose: () => void;
}

const ErrorNotification = ({ open, message, onClose, transition }: ErrorNotificationProps): JSX.Element => {
    if (!open) {
        return <></>;
    }
    return (
        <Dialog PaperProps={{ style: { borderRadius: 0, width: '450px' } }} open={open} onClose={onClose} TransitionComponent={transition}>
            <DialogContent className={styles['dialog-content']}>
                <DialogContentText>
                    <div className={styles.content} data-testid={TestCommon.ERROR_NOTIFICATION_CONTENT_DATA_TEST_ID}>
                        <div>{IMG_FILE_ERROR}</div>
                        <Typography textAlign="center" marginLeft={1} component="span" color={colors.blackText} fontWeight={600} fontSize={15}>
                            <GlobalPreservedSpace>{message}</GlobalPreservedSpace>
                        </Typography>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.action} data-testid={TestCommon.ERROR_NOTIFICATION_ACTION_DATA_TEST_ID}>
                <Button onClick={onClose} className={styles['close-btn']}>
                    {TextCommon.CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ErrorNotification);
