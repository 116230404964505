export const CreateButtonStyle = {
    createMachineControl: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        padding: '4px 15px',
        borderRadius: '6px',
        background: '#1424F9',
        color: '#FFFFFF',
        height: '38px',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            opacity: '.8',
        },
    },
    createMachinePlusIcon: {
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        left: '.5rem',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '0.02rem',
            height: '70%',
            backgroundColor: '#ffffff',
            left: '-20%',
            top: '50%',
            opacity: '0.3',
            transform: 'translateY(-50%)',
        },
    },
};
