import { IMachineGetFromAPI, IMachineItem } from 'src/types/MachineManagement';
import { BaseCommon, MachineCommon } from 'src/common';
import { IMachineLogInfomation } from 'src/types';
import { formatDateToCustomString } from './format.util';

const timeZoneObject: any = MachineCommon.TIME_ZONE_LIST.reduce((object, timezone) => {
    object[timezone.index] = timezone.name;
    return object;
}, {});
const handleDateFormat = (date: string) => {
    const UTCDateString = `${date}Z`;
    if (isNaN(new Date(UTCDateString).getTime())) {
        return MachineCommon.DEFAULT_DATETIME;
    }
    return formatDateToCustomString(new Date(UTCDateString));
};
export const MachineUtils = {
    addNewMachine: (machineList: IMachineItem[], createdMachine: IMachineItem) => {
        if (machineList) {
            machineList = [...machineList, createdMachine];
        }
        return machineList;
    },
    updateMachine: (machineList: IMachineItem[], updatedMachine: IMachineItem) => {
        if (machineList) {
            machineList = machineList.map(machineTmp => {
                if (machineTmp.id === updatedMachine.id) {
                    machineTmp = { ...updatedMachine };
                }
                return machineTmp;
            });
        }
        return machineList;
    },
    deleteMachine: (machineList: IMachineItem[], machineId: string) => {
        if (machineList) {
            machineList = machineList.filter(machine => machine.id !== machineId);
        }
        return machineList;
    },
    handleMachineData: (machineInfo: IMachineGetFromAPI, machineInformation: IMachineLogInfomation) => {
        const finalMachineList: IMachineItem[] = [];
        if (machineInfo && Object.keys(machineInfo).length) {
            Object.keys(machineInfo).forEach((machineId: string) => {
                const finalMachineItem: IMachineItem = {
                    id: machineId ?? BaseCommon.EMPTY_STRING,
                    timeZone: {
                        index: machineInfo[machineId].timeZone,
                        name: timeZoneObject[machineInfo[machineId].timeZone] ?? BaseCommon.EMPTY_STRING,
                    },
                    model: machineInfo[machineId].model ?? BaseCommon.EMPTY_STRING,
                    customer: machineInfo[machineId]?.customer ?? BaseCommon.EMPTY_STRING,
                    name: machineInfo[machineId]?.name ?? BaseCommon.EMPTY_STRING,
                    oldestDatetime: machineInformation.machines[machineId]?.oldestDatetime
                        ? handleDateFormat(machineInformation.machines[machineId]?.oldestDatetime)
                        : MachineCommon.DEFAULT_DATETIME,
                    latestDatetime: machineInformation.machines[machineId]?.latestDatetime
                        ? handleDateFormat(machineInformation.machines[machineId]?.latestDatetime)
                        : MachineCommon.DEFAULT_DATETIME,
                };
                finalMachineList.push(finalMachineItem);
            });
        }
        return finalMachineList;
    },
    handleUpdateList: (list: IMachineItem[], machine: IMachineItem): IMachineItem[] => {
        return list.map(machineTmp => {
            if (machineTmp.id === machine.id) {
                machineTmp = machine;
            }
            return machineTmp;
        });
    },
    isFoundIndex: (machineProperty: string, searchQuery: string): boolean => {
        return machineProperty.toLowerCase().indexOf(searchQuery) > BaseCommon.NOT_FOUND_INDEX;
    },
};
