import { AmplifyS3Config, AWSS3Config } from '../custom/type';
import { BucketType, BrowserStorageKey } from '../types';

const buildAmplifyS3Config = (name: BucketType, bucket: string, region: string, sessionKey: BrowserStorageKey): AmplifyS3Config<BucketType> => {
    return {
        sessionKey,
        name,
        AWSS3: buildS3Config(bucket, region),
    };
};

const buildS3Config = (bucket: string, region: string): AWSS3Config => {
    return {
        bucket,
        region,
    };
};

export { buildAmplifyS3Config, buildS3Config };
