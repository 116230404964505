import EquipmentCommon from './equipment.common';

class MessageCommon {
    public static readonly NOT_FOUND_EQUIPMENT_FILE = `"${EquipmentCommon.ROOT_FILE}" not found.`;
    public static readonly NOT_GET_EQUIPMENT_ID = `Can't read the EquipmentId from "${EquipmentCommon.ROOT_FILE}".`;
    public static readonly INVALID_FILE = `Invalid file`;
    public static readonly UPLOADED_SUCCESS_ALL_FILES = `Uploaded successfully!`;
    public static readonly UPLOADED_SUCCESS_SOME_FILES = `Upload completed with some errors!`;
    public static readonly UPLOADED_ERROR_ALL_FILES = `Upload failed!`;
    public static readonly UPLOADED_THROW_ERROR = `Unexpected Uploading: {}`;
    public static readonly S3_ERROR = `AWS S3 Bucket: {}`;
    public static readonly EXISTED_ERROR = `AWS S3 exists file being uploaded!`;
    public static readonly MESSAGE_ERROR_ALREADY_BLOCKING_FLAG = `The upload is in progress somewhere else`;
    public static readonly INVALID_ACTION_ERROR = 'Invalid Action!';
    public static readonly MESSAGE_ERROR_CREATE_MACHINE = 'Create Machine failed. ({})';
    public static readonly MESSAGE_ERROR_UPDATE_MACHINE = 'Update Machine failed. ({})';
    public static readonly MESSAGE_ERROR_DELETE_MACHINE = 'Delete Machine failed. ({})';
    public static readonly MESSAGE_ERROR_DELETE_LOG_MACHINE = 'Delete Machine log failed. ({})';
    public static readonly MESSAGE_ERROR_EXISTING_MACHINE = 'The Machine ID already exists!';
    public static readonly MESSAGE_ERROR_PAGE_ERROR = `There's something wrong`;
    public static readonly MESSAGE_ERROR_NOT_FOUND_MACHINE = 'Equipment {} not found. Please use Machine Management to create the machine first.';
}

export default MessageCommon;
