import { BucketType, ISetBucket, BrowserStorageKey, IBucketUrl } from '../types';
import { TextCommon, TypeCommon } from '../common';
import { useAuthenticated } from './context';
import { TypeContext } from '../store';
import { ExtLogicS3 } from '../business';
import s3Client, { AmplifyS3Client } from '../custom/amplifys3.client';
import { AmplifyS3Config, AWSS3Config } from '../custom/type';

const useBackdoor = (): any[] => {
    const [appState, dispatch]: TypeContext = useAuthenticated();

    const setOpen = async (callback: () => void, bucketUrls: IBucketUrl[]): Promise<void> => {
        const handledConfigs: (AmplifyS3Config<BucketType> | null)[] = [];

        for (const url of bucketUrls) {
            const returnConfig = await handleBucketUrl(url);
            handledConfigs.push(returnConfig);
        }

        const hasNullValue = handledConfigs.some((item: AmplifyS3Config<BucketType> | null) => item === null);
        if (handledConfigs === null || handledConfigs.length === 0 || hasNullValue) {
            return;
        }
        syncUpConfiguration(handledConfigs as AmplifyS3Config<BucketType>[], callback);
    };

    const syncUpConfiguration = async (s3Config: AmplifyS3Config<BucketType>[], onRefreshParent: () => void): Promise<void> => {
        s3Config.forEach((config: AmplifyS3Config<BucketType>) => ExtLogicS3.storeConfigToSession(config));
        s3Client.updateConfiguration(s3Config);
        dispatch(TypeCommon.setSqliteBucketType(s3Config));
        onRefreshParent();
    };

    const parseUrlToS3Config = (s3Config: AWSS3Config): AmplifyS3Config<BucketType> => {
        // default config
        const config: AmplifyS3Config<BucketType> = {
            sessionKey: BrowserStorageKey.S3_BUCKET_SQLITE,
            name: s3Config.type as BucketType,
            AWSS3: {
                bucket: s3Config.bucket,
                region: s3Config.region,
            },
        };
        switch (s3Config.type) {
            case BucketType.SQLITE:
                config.sessionKey = BrowserStorageKey.S3_BUCKET_SQLITE;
                break;
            case BucketType.BLOCKING:
                config.sessionKey = BrowserStorageKey.S3_BUCKET_CLIENT_BLOCKING;
                break;
        }
        return config;
    };

    const handleBucketUrl = async (bucketUrl: IBucketUrl): Promise<AmplifyS3Config<BucketType> | null> => {
        const urlCleaned = bucketUrl?.url?.trim();
        const s3Config = ExtLogicS3.getBucketAndRegion(urlCleaned);

        // validate the Bucket
        const result = await AmplifyS3Client.validateBucket(s3Config);

        if (result.valid) {
            return parseUrlToS3Config({ ...s3Config, type: bucketUrl.type });
        }
        bucketUrl.errorMessage = result.errorMessage;
        return null;
    };

    return [setOpen];
};

export default useBackdoor;
