import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from 'nxg-ui-wrapper';
import { DeleteConfirmationDialogStyle as styles } from './DeleteConfirmationDialogStyle';
import { useAuthenticated } from 'src/hooks/context';
import { TypeCommon, TextCommon, TestCommon } from 'src/common';
import { DEFAULT_DELETE_DIALOG_WIDTH, DEFAULT_BORDER_RADIUS } from 'src/common/machine.common';
import { TypeContext } from 'src/store';
import { IMachineItem } from 'src/types/MachineManagement';
import { GlobalPreservedSpace } from 'src/components';

interface IDeleteConfirmationDialogProps {
    onDelete: (machineId: string) => void;
    machine: IMachineItem;
}

const DeleteConfirmationDialog = (props: IDeleteConfirmationDialogProps) => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const { onDelete, machine } = props;

    const setCloseDialog = () => dispatch(TypeCommon.setIsOpenDeleteConfirmationDialog(false));
    return (
        <Dialog
            PaperProps={{ style: { width: DEFAULT_DELETE_DIALOG_WIDTH, borderRadius: DEFAULT_BORDER_RADIUS } }}
            BackdropProps={{ style: { background: 'black', opacity: '.7' } }}
            fullWidth={true}
            open={true}
        >
            <DialogContent sx={styles.dialogContent} data-testid={TestCommon.DELETE_MACHINE_CONFIRMATION_DIALOG_DATA_TEST_ID}>
                <Typography component="p" fontWeight={600}>
                    {TextCommon.DELETE_CONFIRMATION_QUESTION_TEXT}
                </Typography>
                <Typography variant="h5" fontWeight={600}>
                    <GlobalPreservedSpace>{machine.id}</GlobalPreservedSpace>
                </Typography>
                <Typography component="p">{TextCommon.DELETE_DATA_AND_LOGS_TOGETHER_TEXT}</Typography>
            </DialogContent>
            <DialogActions sx={styles.dialogAction} data-testid={TestCommon.DELETE_MACHINE_CONFIRMATION_DIALOG_ACTION_DATA_TEST_ID}>
                <Button variant="outlined" sx={styles.cancelButton} onClick={setCloseDialog} name="cancel">
                    {TextCommon.CANCEL}
                </Button>
                <Button variant="contained" sx={styles.deleteButton} onClick={() => onDelete(machine.id)} name="yes">
                    {TextCommon.YES_DELETE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;
