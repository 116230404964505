import { default as React } from 'react';
import styles from './styles.module.css';

interface Props {
    children: React.ReactNode;
}

const GlobalPreservedSpace = ({ children }: Props): JSX.Element => {
    return <div className={styles.preserveSpace}>{children}</div>;
};

export default GlobalPreservedSpace;
