import { colors } from '../../global/style.object';
import { checkboxClasses } from '@mui/material/Checkbox';

export const LogPickerObjectStyle = {
    notSelect: {
        userSelect: 'none',
    },
    logPicker: {
        padding: '0.6rem 1rem',
        justifyContent: 'center',
        margin: 'auto',
        flexDirection: 'row',
    },
    wrapper: {
        fontSize: '1.5rem',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    blueText: {
        color: 'blue',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },

    redText: {
        color: 'red',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },

    browseBtn: {
        background: '#1424f9',
        color: '#ffffff',
        textTransform: 'none',
    },

    pickerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        padding: '5px 20px',
    },
    mainText: {
        fontWeight: '500',
        fontSize: '19px',
    },

    boldText: {
        fontWeight: '600',
    },

    greyItalicText: {
        color: '#6d6f81',
        fontStyle: 'italic',
    },
    item: {
        padding: '0.5rem 0',
        display: 'flex',
        alignItems: 'center',
    },
    refreshCheckbox: {
        margin: '0px',
        width: '42px',
        height: '42px',
    },
    refresh: {
        alignSelf: 'center',
        marginRight: '10px',
        color: '#40414B',
    },
    refreshButton: {
        background: 'transparent linear-gradient(180deg, #ffffff 0%, #d9dade 100%) 0% 0% no-repeat padding-box',
        color: '#000000',
        height: '33px',
        boxShadow: 'none',
        border: '1px solid #00000033',
        padding: '20px 10px',
        marginRight: '15px',
    },
    paperRoot: {
        paddingTop: '0px',
    },
    paper: {
        borderRadius: '0px',
        boxShadow: 'none',
        border: '1px solid #d9dade',
    },
    tableCell: {
        borderBottom: 'none',
    },
    styledCheckBox: {
        color: colors.checkboxOutline,
        [`&.${checkboxClasses.checked}`]: {
            color: colors.checkedCheckBox,
        },
    },
    styleTableCell: {
        background: colors.linearTableHead,
        color: colors.blackText,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '10px',
        fontSize: 14,
    },
    fileTable: {
        customizedTable: {
            border: `1px solid ${colors.tableBorder}`,
            borderCollapse: 'collapse',
            '& th, & td': {
                border: `1px solid ${colors.tableBorder}`,
                padding: '3px 10px',
            },
            '& th': {
                textAlign: 'center',
            },
        },
        boxStyle: {
            display: 'flex',
            gap: '5px',
            justifyContent: 'center',
        },
        subTableHead: {
            background: '#f1f3f7',
            borderTop: '3px solid #d0dbe5',
        },
        cellStyle: {
            textAlign: 'right',
            color: '#006c70',
            textTransform: 'lowercase',
        },
        center: {
            textAlign: 'center',
        },
        gmtTimeZone: {
            opacity: '0.6',
        },
    },
    statusRow: {
        arrowCell: {
            textAlign: 'center',
            width: '5%',
        },
        name: {
            width: '40%',
        },
        borderLine: {
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '1px',
                height: '60%',
                backgroundColor: '#a5afc9',
                top: '50%',
                transform: ' translateY(-50%)',
            },
        },
        bucketProp: {
            padding: '0px 15px',
            fontWeight: '600',
            fontSize: '1rem',
        },
        right: {
            textAlign: 'right',
        },
        fileSize: {
            color: '#006c70',
            textTransform: 'lowercase',
        },
        tableWrapper: {
            border: '5px solid #e2e9fa',
        },
        customizedRow: {
            '& th, & td': {
                background: colors.linearTableRow,
                borderBottom: 'none',
                position: 'relative',
                padding: '3px 10px',
            },
        },
    },
};
