const baseButton = {
    textTransform: 'capitalize',
    padding: '6px 30px',
};
export const DeleteMachineLogDialogStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '11px',
    },

    cancelButton: {
        ...baseButton,
        color: '#B40000',
        border: '1px solid #B40000',
        '&:hover': {
            border: '1px solid #B40000',
            opacity: 0.8,
            background: '#ffffff',
        },
    },
    dialogContent: { padding: '11px 22px 11px 22px' },
    dialogAction: { padding: '0px 22px 11px 22px' },
    textInput: {
        padding: '3px 10px',
        '& input::placeholder': {
            color: '#6D6F81',
            opacity: 1,
        },
        borderRadius: '0px 4px 4px 0px',
    },
    deleteButton: {
        ...baseButton,
        background: '#B40000',
        '&:hover': {
            background: '#B40000',
            opacity: 0.8,
        },
    },
    paper: {
        width: 'fit-content',
        borderRadius: '0',
    },
};
