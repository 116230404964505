import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme } from '@mui/material';
import { ThemeProvider } from 'nxg-ui-wrapper';
import { Amplify } from 'aws-amplify';

import './index.css';
import reportWebVitals from './reportWebVitals';
import awsconfig from './aws-exports';
import App from './pages/App';
import { themeRoot } from './global/style.object';

declare global {
    interface Window {
        showDirectoryPicker: any;
    }
}

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={createTheme(themeRoot)}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
