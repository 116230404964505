/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
import { useCallback, useState } from 'react';
import { Grid, Typography, Button, Box, FormControlLabel, Checkbox } from 'nxg-ui-wrapper';
import { useDropzone } from 'react-dropzone';
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise';
import { EquipmentCommon, TextCommon, BaseCommon, TestCommon } from 'src/common';
import { IC_BROWSE, IC_FORCE_REFRESH, IC_CHECKBOX } from 'src/assets';
import { styled } from '@mui/material';
import { colors } from 'src/global/style.object';
import CommonButton from '../Button';
import { filterFileAndFolder, handleDirectoryEntry, validationFile, formatTime } from '../../utils';
import { FileDb3 } from '../../types';
import { LogPickerObjectStyle as styles } from './LogPickerObjectStyle';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        directory?: string;
        webkitdirectory?: string;
    }
}
export interface LogPickerProps {
    disabled?: boolean;
    filterFolder?: string[];
    filterFileExtensions?: string[];
    onLoading: (value: boolean) => void;
    onLoadedFiles: (files: FileDb3[]) => void;
    onError: (error: any) => void;
    auto: boolean;
    timer: number;
    onRefresh: () => Promise<void>;
    onChangeAuto: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledButton = styled(Button)({
    background: colors.blueButton,
    padding: '8px 20px',
    color: colors.white,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
        background: colors.disabledButton,
        color: colors.white,
    },
});

const LogPicker = ({
    disabled,
    onChangeAuto,
    filterFolder,
    filterFileExtensions,
    onLoading,
    onLoadedFiles,
    onError,
    auto,
    timer,
    onRefresh,
}: LogPickerProps): JSX.Element => {
    const [isOpenPicker, setIsOpenPicker] = useState(false);

    const onDropzoneError = useCallback(async (error: Error) => {
        onError(error);
    }, []);

    useDropzone({
        onError: onDropzoneError,
        noClick: true,
        noKeyboard: true,
    });

    const handleClick = async (): Promise<void> => {
        try {
            if (disabled || isOpenPicker) {
                return;
            }
            let fileSystemDirectoryHandle;
            try {
                setIsOpenPicker(true);
                fileSystemDirectoryHandle = await window.showDirectoryPicker({ mode: 'read' });
            } catch (error) {
                return;
            }
            onLoading(true);
            await validationFile(fileSystemDirectoryHandle);

            // scan deep all folders
            const outputs: FileDb3[] = [];
            await handleDirectoryEntry(fileSystemDirectoryHandle, outputs, [BaseCommon.CHARACTER_SLASH, fileSystemDirectoryHandle.name].join(''));
            const data = await filterFileAndFolder(outputs, filterFolder, filterFileExtensions);
            onLoadedFiles(data);
        } catch (error) {
            onError(error);
        } finally {
            setIsOpenPicker(false);
        }
    };

    const handleDrop = async (evt: any) => {
        try {
            if (disabled || evt.dataTransfer.items.length !== 1) {
                return;
            }
            evt.preventDefault();
            onLoading(true);

            const files = await getFilesFromDataTransferItems(evt.dataTransfer.items);
            const outputs: FileDb3[] = [...files].map((file: any) => {
                file.path = `${BaseCommon.CHARACTER_SLASH}${file.filepath}`;
                return file as FileDb3;
            });
            const data = await filterFileAndFolder(outputs, filterFolder, filterFileExtensions);
            onLoadedFiles(data);
        } catch (error) {
            onError(error);
        }
    };

    return (
        <Grid onDrop={handleDrop} data-testid={TestCommon.LOG_PICKER_DATA_TEST_ID} sx={styles.notSelect}>
            <Box>
                <Box sx={styles.pickerWrapper}>
                    <Typography component="div" sx={styles.wrapper} data-testid={TestCommon.UPLOAD_PICKER_TITLE_DATA_TEST_ID}>
                        <Typography component="span" sx={styles.mainText}>
                            {TextCommon.PICKER_TITLE}&nbsp;
                        </Typography>
                        <Typography component="p" sx={styles.greyItalicText}>
                            <Typography component="span">
                                {TextCommon.OPEN_PARENTHESIS}
                                {TextCommon.SELECT_FOLDER}&nbsp;
                            </Typography>
                            <Typography sx={styles.boldText} component="span">
                                {EquipmentCommon.ROOT_FILE}&nbsp;
                            </Typography>
                            {TextCommon.AND}&nbsp;
                            <Typography sx={styles.boldText} component="span">
                                {TextCommon.LOG_FOLDER}&nbsp;
                            </Typography>
                            {TextCommon.TO}&nbsp;
                            {TextCommon.IMPORT}
                            {TextCommon.DOT}
                            {TextCommon.CLOSE_PARENTHESIS}
                        </Typography>
                    </Typography>
                    <Grid item xs={12} md={6} sx={styles.item} justifyContent="end">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-testid={TestCommon.REFRESH_CHECKBOX_DATA_TEST_ID}
                                    sx={styles.styledCheckBox}
                                    checked={auto}
                                    onChange={onChangeAuto}
                                    checkedIcon={<img src={IC_CHECKBOX} />}
                                />
                            }
                            label=""
                            labelPlacement="bottom"
                            sx={styles.refreshCheckbox}
                        />
                        <Typography component="p" sx={styles.refresh}>
                            {TextCommon.AUTO_REFRESH}
                            {formatTime(timer)} {TextCommon.SECONDS}
                        </Typography>

                        <CommonButton
                            data-testid={TestCommon.FORCE_REFRESH_DATA_TEST_ID}
                            sx={styles.refreshButton}
                            variant="contained"
                            startIcon={<img src={IC_FORCE_REFRESH} />}
                            onClick={onRefresh}
                        >
                            {TextCommon.FORCE_REFRESH}
                        </CommonButton>
                        <StyledButton
                            variant="contained"
                            startIcon={<img height={23} width={20} src={IC_BROWSE} />}
                            onClick={handleClick}
                            disabled={disabled}
                            data-testid={TestCommon.BROWSE_BUTTON_DATA_TEST_ID}
                        >
                            {TextCommon.BROWSE}
                        </StyledButton>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
};

export default LogPicker;
