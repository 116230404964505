import { useEffect, memo } from 'react';
import { CircularProgress, Container, Grid } from 'nxg-ui-wrapper';
import { BaseCommon, TextCommon, TestCommon } from '../../common';
import { LogPicker, Overlay, S3StatusGrid, UploadingDialog } from '../../components';
import { ExtLogicS3 } from '../../business';
import styles from './MainPage.module.css';

import useMainPage from './hooks/useMainPage';

const MainPage = (): JSX.Element => {
    const {
        handleRefresh,
        clearTimer,
        notifyWhenUploadCompleted,
        uploadingFile,
        timer,
        isUploading,
        handleScanning,
        handleError,
        handleLoadedFiles,
        s3File,
        auto,
        handleClickRefresh,
        handleChangeAuto,
        isScanning,
        isShowPopup,
        isUploaded,
        errorReason,
        equipmentId,
        handleCancelUpload,
        handleConfirmUpload,
        intervalId,
        isOtherProcessing,
        isBackendProcessing,
        intervalIdTmp,
        setIntervalIdTmp,
        onUnload,
        loadRdsConfigurationBySession,
    } = useMainPage();

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        loadRdsConfigurationBySession();
        handleRefresh();

        return () => {
            window.removeEventListener('beforeunload', onUnload);
            clearTimer();
        };
    }, []);

    useEffect(() => {
        if (ExtLogicS3.isCompletedProgress(uploadingFile)) {
            ExtLogicS3.updateBlockingFlag(false).finally(() => notifyWhenUploadCompleted());
        }
    }, [uploadingFile]);

    useEffect(() => {
        clearInterval(intervalIdTmp);
        setIntervalIdTmp(intervalId);
    }, [intervalId]);

    useEffect(() => {
        if (timer === BaseCommon.FILE_REFRESH_TIME_SECOND) {
            handleRefresh();
        }
    }, [timer]);

    return (
        <>
            <Container maxWidth={false} className={styles.container} data-testid={TestCommon.MAIN_PAGE_DATA_TEST_ID}>
                <Grid container rowSpacing={1} direction="column" className={styles.wrapper}>
                    <Grid item className={styles.item}>
                        <LogPicker
                            disabled={isOtherProcessing || isBackendProcessing || isUploading}
                            filterFolder={[...BaseCommon.VALID_LOG_FOLDERS, '']}
                            filterFileExtensions={BaseCommon.APP_ACCEPT_EXTENSION_FILES}
                            onLoading={handleScanning}
                            onError={handleError}
                            onLoadedFiles={handleLoadedFiles}
                            auto={auto}
                            timer={timer}
                            onRefresh={handleClickRefresh}
                            onChangeAuto={handleChangeAuto}
                        />
                    </Grid>
                    <S3StatusGrid data={s3File} />
                </Grid>
            </Container>
            <Overlay open={isScanning} content={TextCommon.SCANNING} element={<CircularProgress color="inherit" />} />
            <UploadingDialog
                open={isShowPopup}
                data={uploadingFile}
                isUploading={isUploading}
                isUploaded={isUploaded}
                errorReason={errorReason}
                title={equipmentId}
                onClose={handleCancelUpload}
                onUpload={handleConfirmUpload}
            />
        </>
    );
};

export default memo(MainPage);
