import { Box, Typography } from 'nxg-ui-wrapper';
import React from 'react';
import { TextCommon, TypeCommon, TestCommon } from 'src/common';
import { CreateButtonStyle as styles } from './CreateButtonStyle';
import { IC_CREATE_MACHINE, IC_PLUS } from 'src/assets';
import { TypeContext } from 'src/store';
import { useAuthenticated } from 'src/hooks/context';

const CreateButton = () => {
    const [appState, dispatch]: TypeContext = useAuthenticated();

    const setOpenCreateDialog = () => dispatch(TypeCommon.setIsOpenCreateMachineDialog(true));

    return (
        <Box
            component="div"
            sx={styles.createMachineControl}
            role="button"
            onClick={setOpenCreateDialog}
            data-testid={TestCommon.CREATE_BUTTON_DATA_TEST_ID}
        >
            <img src={IC_CREATE_MACHINE} width={21} height={18} />
            <Typography>{TextCommon.CREATE_MACHINE_LABEL}</Typography>
            <Box sx={styles.createMachinePlusIcon}>
                <img src={IC_PLUS} width={21} height={18} />
            </Box>
        </Box>
    );
};

export default React.memo(CreateButton);
