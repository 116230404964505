/* eslint-disable @typescript-eslint/no-inferrable-types */
import { uploadData, list, remove, ListPaginateWithPathOutput } from 'aws-amplify/storage';
import { BaseCommon } from '../common';
import { BucketType } from '../types';
import { AmplifyS3Config, AWSS3Config, ValidationResult, UploadCallback } from './type';
import { Amplify } from 'aws-amplify';
import { EMPTY_STRING } from 'src/common/base.common';

export class AmplifyS3Client<T> {
    private static _instance = new AmplifyS3Client<BucketType>(BaseCommon.ALL_S3_CONFIGS);
    private __configs: AmplifyS3Config<T>[] = [];

    private constructor(__configs: AmplifyS3Config<T>[]) {
        if (__configs.length > 0) {
            this.__configs = __configs;
        }
    }

    private static _overwriteAmplifyStorage(s3Config: AWSS3Config): void {
        Amplify.configure({
            ...Amplify.getConfig(),
            Storage: {
                S3: {
                    bucket: s3Config.bucket,
                    region: s3Config.region,
                },
            },
        });
    }

    public static async validateBucket(s3Config: AWSS3Config): Promise<ValidationResult> {
        try {
            AmplifyS3Client._overwriteAmplifyStorage(s3Config);
            await list({
                path: EMPTY_STRING,
                options: {
                    listAll: true,
                },
            });
            return {
                valid: true,
            };
        } catch (error: any) {
            console.error('ERROR::VALIDATE_BUCKET:: ', error);
            return {
                valid: false,
                errorMessage: error.message,
            };
        }
    }

    public static getInstance<T>(): AmplifyS3Client<T> {
        return this._instance as unknown as AmplifyS3Client<T>;
    }

    getInstance(name: T): AmplifyS3Client<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.name === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        AmplifyS3Client._overwriteAmplifyStorage(config.AWSS3);
        return this;
    }

    getInstanceByBucketName(name: string): AmplifyS3Client<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.AWSS3.bucket === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        AmplifyS3Client._overwriteAmplifyStorage(config.AWSS3);
        return this;
    }

    getConfigOfInstance(name: T): AmplifyS3Config<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.name === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        return config;
    }

    updateConfiguration(s3Config: AmplifyS3Config<T>[]): AmplifyS3Client<T> {
        const s3ConfigObj: any = {};
        s3Config.forEach((itm: AmplifyS3Config<T>): void => {
            s3ConfigObj[itm.name] = itm;
        });
        this.__configs = [...this.__configs].map((instance: AmplifyS3Config<T>) => {
            try {
                if (s3ConfigObj[instance.name]) {
                    return s3ConfigObj[instance.name];
                }
                return instance;
            } catch (error) {
                console.error(error);
            }
        });
        return this;
    }

    async list(path: string = EMPTY_STRING): Promise<ListPaginateWithPathOutput> {
        return await list({
            path,
            options: {
                listAll: true,
            },
        });
    }

    async put(path: string, object: any, config?: UploadCallback): Promise<any> {
        return await uploadData({
            path,
            data: object,
            options: {
                onProgress: config?.onProgress,
            },
        }).result;
    }

    async remove(path: string, config?: UploadCallback): Promise<any> {
        return await remove({
            path,
            ...config,
        });
    }
}

const s3Client = AmplifyS3Client.getInstance<BucketType>();
export default s3Client;
