export const EDIT_BUTTON_DATA_TEST_ID = 'edit-button';
export const DELETE_BUTTON_DATA_TEST_ID = 'delete-button';
export const LOG_INFO_BUTTON_DATA_TEST_ID = 'log-info-button';
export const CONFIRM_BUTTON_DATA_TEST_ID = 'confirm-button';
export const CREATE_BUTTON_DATA_TEST_ID = 'create-button';
export const CREATE_MACHINE_DIALOG_BODY_DATA_TEST_ID = 'create-dialog-body';
export const CREATE_MACHINE_DIALOG_ACTION_DATA_TEST_ID = 'create-dialog-action';
export const MODEL_SELECT_BOX_DATA_TEST_ID = 'model-select';
export const TIMEZONE_SELECT_BOX_DATA_TEST_ID = 'timezone-select';
export const TIMEZONE_ITEM_DATA_TEST_ID = 'timezone-item';
export const MODEL_ITEM_DATA_TEST_ID = 'model-item';
export const DELETE_MACHINE_CONFIRMATION_DIALOG_DATA_TEST_ID = 'delete-confirmation-dialog';
export const DELETE_MACHINE_CONFIRMATION_DIALOG_ACTION_DATA_TEST_ID = 'delete-confirmation-action';
export const DELETE_MACHINE_DIALOG_DATA_TEST_ID = 'delete-dialog';
export const DELETE_MACHINE_DIALOG_ACTION_DATA_TEST_ID = 'delete-dialog-dialog';
export const DROPDOWN_MENU_DATA_TEST_ID = 'dropdown-menu';
export const MENU_LIST_DATA_TEST_ID = 'menu-list';
export const MENU_ITEM_DATA_TEST_ID = 'menu-item';
export const ERROR_NOTIFICATION_CONTENT_DATA_TEST_ID = 'error-notification-content';
export const ERROR_NOTIFICATION_ACTION_DATA_TEST_ID = 'error-notification-action';
export const HEADER_DATA_TEST_ID = 'header';
export const HEADER_TITLE_DATA_TEST_ID = 'header-title';
export const MENU_ICON_DATA_TEST_ID = 'menu-icon';
export const LOGOUT_ICON_DATA_TEST_ID = 'logout-icon';
export const BACKDOOR_POSITION_DATA_TEST_ID = 'backdoor-position';
export const INPUT_FIELD_DATA_TEST_ID = 'input-field';
export const DOWN_ARROW_ICON_DATA_TEST_ID = 'down-arrow-icon';
export const UP_ARROW_ICON_DATA_TEST_ID = 'up-arrow-icon';
export const PROGRESS_LABEL_DATA_TEST_ID = 'progress-label';
export const LOG_PICKER_DATA_TEST_ID = 'log-picker';
export const UPLOAD_PICKER_TITLE_DATA_TEST_ID = 'upload-picker-title';
export const REFRESH_CHECKBOX_DATA_TEST_ID = 'refresh-checkbox';
export const FORCE_REFRESH_DATA_TEST_ID = 'force-refresh';
export const BROWSE_BUTTON_DATA_TEST_ID = 'browse-button';
export const NO_DATA_DATA_TEST_ID = 'no-data';
export const OVERLAY_DATA_TEST_ID = 'overlay';
export const S3_STATUS_GRID_DATA_TEST_ID = 's3-status-grid';
export const SIGN_IN_HEADER_DATA_TEST_ID = 'sign-in-header';
export const TOTAL_LABEL_DATA_TEST_ID = 'total-label';
export const UPDATE_MACHINE_DIALOG_BODY_DATA_TEST_ID = 'update-dialog-body';
export const UPDATE_MACHINE_DIALOG_ACTION_DATA_TEST_ID = 'update-dialog-action';
export const UPLOAD_STATUS_DATA_TEST_ID = 'upload-status';
export const CLOSE_BUTTON_DATA_TEST_ID = 'close-button';
export const CANCEL_BUTTON_DATA_TEST_ID = 'cancel-button';
export const UPLOAD_BUTTON_DATA_TEST_ID = 'upload-button';
export const UPLOAD_DIALOG_DATA_TEST_ID = 'upload-dialog';
export const UPLOAD_DIALOG_FOOTER_DATA_TEST_ID = 'upload-dialog-footer';
export const UPLOAD_EQUIPMENT_ID_DATA_TEST_ID = 'upload-equipment-id';
export const MAIN_PAGE_DATA_TEST_ID = 'main-page';
export const SQLITE_BUCKET_DATA_TEST_ID = 'sqlite-bucket';
export const BLOCKING_BUCKET_DATA_TEST_ID = 'blocking-bucket';
export const TABLE_BODY_ROW_DATA_TEST_ID = 'tableBodyRowDataTestId';
export const CUSTOM_TABLE_BODY_ROW_DATA_TEST_ID = 'customTableBodyDataTestId';
export const NO_MATCHING_DATA_TEST_ID = 'noMatchingDataTestId';
export const SEARCH_BOX_DATA_TEST_ID = 'searchBoxDataTestId';
export const DELETE_MACHINE_LOG_DIALOG_DATA_TEST_ID = 'delete-log-dialog';
export const DELETE_MACHINE_LOG_CONFIRMATION_DIALOG_DATA_TEST_ID = 'delete-log-confirmation-dialog';
export const DELETE_MACHINE_LOG_CONFIRMATION_DIALOG_ACTION_DATA_TEST_ID = 'delete-log-confirmation-action';
export const DELETE_MACHINE_LOG_DIALOG_ACTION_DATA_TEST_ID = 'delete-dialog-action';
