import React from 'react';
import { Stack, Paper, MenuList, MenuItem, Typography } from 'nxg-ui-wrapper';
import styles from './DropdownMenu.module.css';
import { IPageDef } from 'src/types';
import { Link } from 'react-router-dom';
import { TestCommon } from 'src/common';
interface DropdownMenuProp {
    pagesData: IPageDef[];
}
const DropdownMenu = (props: DropdownMenuProp): JSX.Element => {
    return (
        <Stack direction="row" spacing={2} data-testid={TestCommon.DROPDOWN_MENU_DATA_TEST_ID}>
            <Paper className={styles.paper}>
                <MenuList data-testid={TestCommon.MENU_LIST_DATA_TEST_ID}>
                    {props.pagesData?.map((item, idx) => (
                        <Link to={item.navLink} className={styles['menuLink']} key={idx}>
                            <MenuItem className={styles.menu} role={'button'} data-testid={TestCommon.MENU_ITEM_DATA_TEST_ID}>
                                <img src={item.iconSrc} width={25} height={25} />
                                <Typography marginLeft={2} className={styles['menu-label']}>
                                    {item.label}
                                </Typography>
                            </MenuItem>
                        </Link>
                    ))}
                </MenuList>
            </Paper>
        </Stack>
    );
};

export default DropdownMenu;
