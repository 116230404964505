import { memo, useEffect, useState } from 'react';
import { MUITable, Typography, Box, ITableComponentProps, ITableColumn } from 'nxg-ui-wrapper';
import { S3StatusGridStyle as styles } from './S3StatusGridStyle';
import { S3StatusBucket, S3StatusObjectFile } from '../../types';
import { BaseCommon, TextCommon, TestCommon } from '../../common';
import { formatDate, convertUnitFileSize } from '../../utils';
import TotalLabel from 'src/components/TotalLabel';
export interface S3StatusGridProp {
    data: S3StatusBucket;
}

const TABLE_COLUMNS: ITableColumn[] = [
    {
        title: TextCommon.EQUIPMENT,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.EQUIPMENT_ID,
    },
    {
        title: TextCommon.FILE,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.FILE,
    },
    {
        title: TextCommon.SIZE,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.SIZE,
        align: 'right',
    },
    {
        title: TextCommon.DATE,
        key: BaseCommon.FILE_TABLE_COLUMN_KEY.DATE,
    },
];

const S3StatusGrid = ({ data }: S3StatusGridProp): JSX.Element => {
    const [gridDataTable, setGridDataTable] = useState<ITableComponentProps>();
    const renderBucketInfo = (totalFile: number, totalSize: number) => {
        return (
            <>
                <TotalLabel label={TextCommon.TOTAL_FILE} value={totalFile} />
                <TotalLabel label={TextCommon.TOTAL_SIZE} value={`${TextCommon.TILDE}${convertUnitFileSize(totalSize)}`} />
            </>
        );
    };

    const handleDateString = (object: S3StatusObjectFile): JSX.Element => {
        const time = object?.lastModified?.toString() ?? BaseCommon.EMPTY_STRING;
        const timeZone = time.substring(time.indexOf(BaseCommon.CHARACTER_TIMEZONE_GMT), time.length);
        const timeUpload = formatDate(new Date(object.lastModified));

        return (
            <Box sx={styles.fileTable.boxStyle}>
                {timeUpload === BaseCommon.EMPTY_STRING ? (
                    <Typography>{time}</Typography>
                ) : (
                    <>
                        <Typography>{timeUpload}</Typography>
                        <Typography>{timeZone}</Typography>
                    </>
                )}
            </Box>
        );
    };

    const handleTableData = (bucketData: S3StatusBucket) => {
        if (Object.keys(bucketData).length) {
            const rows = bucketData?.objects?.map((file: S3StatusObjectFile, index: number) => {
                return {
                    key: index.toString(),
                    equipmentId: file.equipmentId,
                    file: file.key,
                    size: convertUnitFileSize(file.size),
                    date: handleDateString(file),
                };
            });
            const tableComponentPropsData: ITableComponentProps = {
                header: bucketData.name,
                rows,
                columns: TABLE_COLUMNS,
                hasNumberColumn: true,
                customHeaderRight: renderBucketInfo(bucketData.totalFile, bucketData.totalSize),
            };

            setGridDataTable(tableComponentPropsData);
        }
    };
    useEffect(() => {
        handleTableData(data);
    }, [data]);

    const renderTables = (): JSX.Element => {
        if (gridDataTable !== undefined) {
            return (
                <>
                    <Box sx={styles.tableWrapper}>
                        <MUITable tableComponentProps={{ ...gridDataTable }} tableContainerProps={{ sx: styles.tableContainerSx }} />
                    </Box>
                </>
            );
        }
        return <></>;
    };

    return (
        <Box sx={styles.tablesContainer} data-testid={TestCommon.S3_STATUS_GRID_DATA_TEST_ID}>
            {renderTables()}
        </Box>
    );
};

export default memo(S3StatusGrid);
