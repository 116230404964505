import { useTheme } from '@mui/material';
import { Backdrop, Typography } from 'nxg-ui-wrapper';

import globalStyles from '../../global/style.module.css';
import { TestCommon } from 'src/common';
interface OverlayProps {
    content: string;
    open: boolean;
    element?: JSX.Element;
}

const Overlay = (props: OverlayProps): JSX.Element => {
    const { open, content, element } = props;
    const theme = useTheme();

    const MARGIN_10 = {
        marginLeft: '10px',
    };

    return (
        <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1 }} open={open} data-testid={TestCommon.OVERLAY_DATA_TEST_ID}>
            {element && (
                <Typography component="div" className={globalStyles.white}>
                    {element}
                </Typography>
            )}
            <Typography sx={MARGIN_10} className={`${globalStyles.white} ${globalStyles.ml1}`}>
                {content}
            </Typography>
        </Backdrop>
    );
};

export default Overlay;
