export const IMG_FILE_ERROR: JSX.Element = (
    <svg xmlns="http://www.w3.org/2000/svg" width="83.998" height="72.238" viewBox="0 0 83.998 72.238">
        <g id="ic_mn_adm_grey" transform="translate(-0.1 -4.9)">
            <path
                id="Path_9957"
                data-name="Path 9957"
                d="M79.418,77.138H4.66A4.558,4.558,0,0,1,.1,72.578V9.46A4.558,4.558,0,0,1,4.66,4.9H79.538A4.558,4.558,0,0,1,84.1,9.46V72.7A4.724,4.724,0,0,1,79.418,77.138Z"
                transform="translate(0 0)"
                fill="#aa072d"
            />
            <path
                id="Path_9964"
                data-name="Path 9964"
                d="M70.806,69.2H4.165A4.06,4.06,0,0,1,.1,65.144V8.959A4.06,4.06,0,0,1,4.165,4.9H70.913a4.06,4.06,0,0,1,4.065,4.059V65.251A4.209,4.209,0,0,1,70.806,69.2Z"
                transform="translate(4.56 7.935)"
                fill="#eee"
            />
            <g id="Group_528" data-name="Group 528" transform="translate(4.9 15.7)">
                <path
                    id="Path_9958"
                    data-name="Path 9958"
                    d="M4.1,29.74V24.1c.24-.36.48-.36.84-.36h39c1.2,0,1.32-.12,1.56-1.32a12.015,12.015,0,0,1,3.84-6.24,9.589,9.589,0,0,1,6-2.28H76.458a2.374,2.374,0,0,1,1.32.24,3.677,3.677,0,0,1,.48,2.04v3.84H55.7a5.013,5.013,0,0,0-5.04,4.2,4.082,4.082,0,0,0-.12,1.2c-.24,2.52-1.44,4.08-3.72,4.32a11.642,11.642,0,0,1-2.04.12H5.06C4.94,29.74,4.58,29.74,4.1,29.74Z"
                    transform="translate(-4.1 -13.9)"
                    fill="#d3d1d2"
                />
            </g>
            <path
                id="Path_9959"
                data-name="Path 9959"
                d="M79.418,74.358H4.66A4.558,4.558,0,0,1,.1,69.8V33.44a5.761,5.761,0,0,1,5.76-5.76H47.619l3.72-6.96a3.459,3.459,0,0,1,3.12-1.92H80.378a3.536,3.536,0,0,1,3.6,3.6V69.8A4.558,4.558,0,0,1,79.418,74.358Z"
                transform="translate(0 2.78)"
                fill="#c63051"
            />
            <path
                id="Path_9960"
                data-name="Path 9960"
                d="M58.44,23.8H47.76a1.23,1.23,0,0,1,0-2.4H69.119a1.21,1.21,0,0,1,1.08,1.32,1.173,1.173,0,0,1-1.08,1.2h-.84C64.919,23.8,61.68,23.8,58.44,23.8Z"
                transform="translate(9.338 3.299)"
                fill="#eeeced"
            />
            <path
                id="Path_9961"
                data-name="Path 9961"
                d="M35.944,47.038A7.283,7.283,0,0,1,30.9,45c-.72-.72-.84-1.44-.24-2.04a1.348,1.348,0,0,1,1.92.12c2.16,1.92,4.44,1.92,6.72,0a1.348,1.348,0,0,1,1.92-.12,1.363,1.363,0,0,1-.24,1.92A6.313,6.313,0,0,1,35.944,47.038Z"
                transform="translate(77.835 97.201) rotate(180)"
                fill="#eeeced"
            />
            <path
                id="Path_9962"
                data-name="Path 9962"
                d="M21.62,42.96v1.56c-.12.84-.6,1.32-1.2,1.32a1.308,1.308,0,0,1-1.32-1.2V41.4a1.134,1.134,0,0,1,1.2-1.2,1.308,1.308,0,0,1,1.32,1.2v1.56Z"
                transform="matrix(0.259, 0.966, -0.966, 0.259, 60.444, 19.278)"
                fill="#eeeced"
            />
            <path
                id="Path_9963"
                data-name="Path 9963"
                d="M51.335,42.98v1.68c-.12.72-.48,1.2-1.32,1.08a1.108,1.108,0,0,1-1.2-1.08,16.865,16.865,0,0,1,0-3.48c0-.72.48-1.08,1.2-1.08a1.21,1.21,0,0,1,1.32,1.08A9,9,0,0,1,51.335,42.98Z"
                transform="matrix(0.259, -0.966, 0.966, 0.259, 5.358, 87.208)"
                fill="#eeeced"
            />
            <path
                id="Path_9965"
                data-name="Path 9965"
                d="M52.138,56.592c0,2.6-1.525,3.531-3.128,3.531s-3.128-.926-3.128-3.531S49.01,49.3,49.01,49.3,52.138,53.987,52.138,56.592Z"
                transform="translate(10.217 4.703)"
                fill="#eeeced"
            />
        </g>
    </svg>
);
