import { useState } from 'react';
import { signOut } from 'aws-amplify/auth';
import useTimer from 'src/hooks/useTimer';
import useBackdoor from 'src/hooks/useBackdoor';
import { TypeCommon } from 'src/common';
import { useAuthenticated } from 'src/hooks/context';
import { ExtLogicS3 } from '../../../business';
import { TypeContext } from 'src/store';

interface IHeaderHook {
    handleIsShowMenu: () => void;
    handleSetIsShowLabel: () => void;
    handleSetIsHideLabel: () => void;
    handleSignOut: () => void;
    handleOpenBackdoor: () => Promise<void>;
    handleForceRemove: () => Promise<void>;
    isShowLabel: boolean;
    isShowMenu: boolean;
    isUploading: boolean;
}
const useHeader = (): IHeaderHook => {
    const [setOpen] = useBackdoor();
    const { handleRefresh } = useTimer();
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const { isUploading, isShowMenu, isOpenBackDoor } = appState;
    const [isShowLabel, setIsShowLabel] = useState<boolean>(false);

    const handleIsShowMenu = (): void => {
        dispatch(TypeCommon.setIsShowMenu(!isShowMenu));
    };

    const handleSetIsShowLabel = (): void => {
        setIsShowLabel(true);
    };
    const handleSetIsHideLabel = (): void => {
        setIsShowLabel(false);
    };

    const handleSignOut = (): void => {
        signOut();
    };

    const handleOpenBackdoor = async (): Promise<void> => {
        dispatch(TypeCommon.setIsOpenBackDoor(!isOpenBackDoor));
    };

    const handleForceRemove = async (): Promise<void> => {
        await ExtLogicS3.updateBlockingFlag(false);
        await handleRefresh();
    };

    return {
        handleSignOut,
        handleOpenBackdoor,
        handleSetIsShowLabel,
        handleSetIsHideLabel,
        handleIsShowMenu,
        isShowLabel,
        isShowMenu,
        isUploading,
        handleForceRemove,
    };
};

export default useHeader;
