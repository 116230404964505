import { Box, Typography, Button } from 'nxg-ui-wrapper';
import { TextCommon, TestCommon } from '../../common';
import styles from './UploadingDialog.module.css';
import { styled } from '@mui/material/styles';
import { colors } from 'src/global/style.object';
import { CircularProgress } from '@mui/material';
import { ENotifyType } from 'src/types';

interface Props {
    isUploaded: boolean;
    isUploading: boolean;
    handleClose: () => void;
    handleUpload: () => void;
    notify: any;
}
const DialogFooter = (props: Props): JSX.Element => {
    const { isUploaded, isUploading, handleClose, handleUpload, notify } = props;
    const GradientCircularProgress = styled(CircularProgress)(({ theme }) => ({
        circle: {
            stroke: colors.circleProgress,
        },
    }));
    const renderMessageColor = (type: string | undefined): string => {
        if (type === ENotifyType.SUCCESS) {
            return colors.successMessage;
        }
        return colors.errorColor;
    };

    if (isUploaded) {
        return (
            <>
                <Typography data-testid={TestCommon.UPLOAD_STATUS_DATA_TEST_ID} color={renderMessageColor(notify.type)}>
                    {notify.message}
                </Typography>
                <Button
                    data-testid={TestCommon.CLOSE_BUTTON_DATA_TEST_ID}
                    variant="contained"
                    onClick={handleClose}
                    className={`${styles['base-style']} ${styles['upload']}`}
                >
                    {TextCommon.CLOSE}
                </Button>
            </>
        );
    }

    if (isUploading && !isUploaded) {
        return (
            <>
                <Box className={` ${styles['proccessing']}`}>
                    {TextCommon.PROCCESSING}
                    <GradientCircularProgress size={17} />
                </Box>
            </>
        );
    }

    return (
        <>
            <Button
                data-testid={TestCommon.CANCEL_BUTTON_DATA_TEST_ID}
                variant="outlined"
                onClick={handleClose}
                className={`${styles['base-style']} ${styles['light-btn']}`}
            >
                {TextCommon.CANCEL}
            </Button>
            <Button
                data-testid={TestCommon.UPLOAD_BUTTON_DATA_TEST_ID}
                onClick={handleUpload}
                variant="contained"
                className={`${styles['base-style']} ${styles['upload']}`}
            >
                {TextCommon.UPLOAD}
            </Button>
        </>
    );
};

export default DialogFooter;
