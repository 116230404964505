export const CHECKED: JSX.Element = (
    <svg id="ic_checked" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
        <g id="Layer_1" data-name="Layer 1">
            <rect id="Rectangle_2119" data-name="Rectangle 2119" width="19" height="19" fill="none" />
            <path
                id="Path_9951"
                data-name="Path 9951"
                d="M0,9.54c.33-.25.6-.55.9-.82.57-.52,1.12-1,1.68-1.57.11-.11.16-.09.26,0,1.27,1.36,2.55,2.72,3.82,4.09.11.12.17.12.29,0L9.49,8.9a.32.32,0,0,1,.06.25v4.56a.64.64,0,0,1,0,.2l-2.8,2.6c-.09.09-.14.09-.23,0L1.26,10.9,0,9.57Z"
                fill="#89da53"
            />
            <path
                id="Path_9952"
                data-name="Path 9952"
                d="M9.53,13.91c-.06-.06,0-.14,0-.21V8.9l1.3-1.21,3.34-3.1,2.22-2.07c.12-.11.17-.08.26,0,.77.85,1.56,1.7,2.34,2.55h0l-1.5,1.38-7.43,6.89Z"
                fill="#5dc177"
            />
        </g>
    </svg>
);
export const UPLOAD_COMPLETE: JSX.Element = (
    <svg id="ic_progress_error" xmlns="http://www.w3.org/2000/svg" width="18.99" height="19" viewBox="0 0 18.99 19">
        <g id="Layer_1" data-name="Layer 1">
            <path
                id="Path_9945"
                data-name="Path 9945"
                d="M9.5,19H5.64a1,1,0,0,1-.56-.33L.34,13.91A1,1,0,0,1,0,13.36V5.64a1,1,0,0,1,.34-.56L5.1.32A1,1,0,0,1,5.68,0H9.5a.26.26,0,0,1,.08.23c0,1.17,0,2.33,0,3.49a.45.45,0,0,1,0,.2A.4.4,0,0,1,9.29,4H8.85a.51.51,0,0,0-.51.59c.05.62.09,1.23.14,1.85s.11,1.46.17,2.19c0,.57.09,1.15.12,1.72a1.445,1.445,0,0,0,.05.46.46.46,0,0,0,.44.43.311.311,0,0,1,.27.09c.07.09,0,.05,0,.08a7.71,7.71,0,0,1,0,1.36c-.05.14-.19.14-.3.17a1.016,1.016,0,0,0-.02,1.97c.12,0,.26,0,.32.17a.49.49,0,0,1,0,.2V18.7C9.58,18.82,9.59,18.92,9.5,19Z"
                fill="#f15a3c"
            />
            <path
                id="Path_9946"
                data-name="Path 9946"
                d="M9.5,3.93V0h3.86a.93.93,0,0,1,.5.28l4.85,4.86a.84.84,0,0,1,.28.5v7.72a.87.87,0,0,1-.28.5q-2.43,2.42-4.85,4.85a.87.87,0,0,1-.5.28H9.5V15a.43.43,0,0,1,.25-.1,1.036,1.036,0,0,0-.01-2,.72.72,0,0,1-.22-.08V11.27a.31.31,0,0,1,.21-.07.47.47,0,0,0,.47-.47c.07-.64.09-1.28.15-1.92s.11-1.47.17-2.21c.05-.57.09-1.13.12-1.7V4.54A.51.51,0,0,0,10.12,4a1.63,1.63,0,0,1-.58,0,.1.1,0,0,1-.04-.07Z"
                fill="#e33539"
            />
            <path
                id="Path_9947"
                data-name="Path 9947"
                d="M9.5,11.36H9.26a.55.55,0,0,1-.54-.51c-.09-1.15-.17-2.29-.26-3.44L8.25,4.64a.58.58,0,0,1,.64-.71H9.5a.19.19,0,0,1,.08.17v6.86a.625.625,0,0,1-.08.38Z"
                fill="#e8f2fa"
            />
            <path
                id="Path_9948"
                data-name="Path 9948"
                d="M9.5,15.07a1.1,1.1,0,0,1-1-.68,1.06,1.06,0,0,1,.14-1.12,1.12,1.12,0,0,1,.88-.44h0a.26.26,0,0,1,.08.23c0,.59,0,1.17,0,1.75a.27.27,0,0,1-.08.24Z"
                fill="#e8f2fa"
            />
            <path
                id="Path_9949"
                data-name="Path 9949"
                d="M9.5,11.35V3.94h.7a.54.54,0,0,1,.55.57c0,.41-.05.82-.08,1.24s-.09,1.14-.13,1.71l-.18,2.31a4.263,4.263,0,0,1-.08,1c-.08.34-.22.6-.67.6Z"
                fill="#d5e8f7"
            />
            <path id="Path_9950" data-name="Path 9950" d="M9.5,15.06V12.84a1.11,1.11,0,1,1,0,2.22Z" fill="#d5e8f7" />
        </g>
    </svg>
);
