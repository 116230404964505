import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Box, Button, InputBase, Select, MenuItem } from 'nxg-ui-wrapper';
import InputField from '../InputField';
import { CreateMachineDialogStyle as styles } from './CreateMachineDialogStyle';
import { useAuthenticated } from 'src/hooks/context';
import { TypeContext } from 'src/store';
import { MachineCommon, TypeCommon, TextCommon, TestCommon } from 'src/common';
import { TimeZone, IMachineItem } from 'src/types/MachineManagement';
import { IMG_NO_MACHINE_IMAGE } from 'src/assets';
import { colors } from 'src/global/style.object';
import { PlaceHolder } from 'src/components';

interface ICreateMachineDialogProps {
    modelList: string[];
    onCreate: () => void;
    setMachine: React.Dispatch<React.SetStateAction<IMachineItem>>;
    machine: IMachineItem;
    validationError: string;
}
const CreateMachineDialog = (props: ICreateMachineDialogProps) => {
    const { modelList, onCreate, setMachine, machine, validationError } = props;
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const setCloseDialog = () => {
        setMachine(MachineCommon.DEFAULT_MACHINE_ITEM);
        dispatch(TypeCommon.setIsOpenCreateMachineDialog(false));
    };

    const handleOnChangeNewMachineObj = (e: any) => {
        const setMachineCallBack = (machine: IMachineItem) => {
            const { name, value } = e.target;
            if (e.target.name === MachineCommon.TIME_ZONE_KEY_NAME) {
                machine[name] = MachineCommon.TIME_ZONE_LIST.find((timezone: TimeZone) => timezone.index === value);
            } else {
                machine[name] = value;
            }
            return machine;
        };
        setMachine(previousMachine => setMachineCallBack({ ...previousMachine }));
    };

    const renderModelValue = () => {
        if (machine?.model) {
            return machine?.model;
        }
        return <PlaceHolder text={TextCommon.MODEL_PLACEHOLDER} />;
    };

    const renderTimeZoneValue = () => {
        if (machine?.timeZone?.index !== undefined) {
            return machine?.timeZone.name;
        }
        return <PlaceHolder text={TextCommon.TIME_ZONE_PLACEHOLDER} />;
    };

    useEffect(() => {
        if (machine?.id && machine?.model && machine?.timeZone?.index !== undefined) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [JSON.stringify(machine)]);

    return (
        <Dialog maxWidth="md" PaperProps={{ style: { width: 'fit-content', borderRadius: '0' } }} fullWidth={true} open={true}>
            <DialogContent sx={styles.dialogContent} data-testid={TestCommon.CREATE_MACHINE_DIALOG_BODY_DATA_TEST_ID}>
                <Typography variant="h5">{TextCommon.CREATE_MACHINE_TITLE}</Typography>
                <Box sx={styles.formWrapper}>
                    <InputField required label={TextCommon.MACHINE_ID_LABEL} isError={Boolean(validationError)}>
                        <InputBase
                            sx={styles.textInput}
                            type="text"
                            placeholder={TextCommon.MACHINE_ID_PLACEHOLDER}
                            name="id"
                            value={machine?.id}
                            onChange={e => handleOnChangeNewMachineObj(e)}
                        />
                    </InputField>
                    <InputField label={TextCommon.MACHINE_NAME_LABEL}>
                        <InputBase
                            sx={styles.textInput}
                            type="text"
                            placeholder={TextCommon.MACHINE_NAME_PLACEHOLDER}
                            name="name"
                            value={machine?.name}
                            onChange={e => handleOnChangeNewMachineObj(e)}
                        />
                    </InputField>
                    <InputField required label={TextCommon.MODEL_LABEL}>
                        <Select
                            renderValue={renderModelValue}
                            native={false}
                            displayEmpty
                            sx={styles.selectField}
                            variant="standard"
                            disableUnderline
                            name="model"
                            onChange={e => handleOnChangeNewMachineObj(e)}
                            data-testid={TestCommon.MODEL_SELECT_BOX_DATA_TEST_ID}
                        >
                            {modelList?.map((item, idx) => (
                                <MenuItem key={idx} value={item} data-testid={TestCommon.MODEL_ITEM_DATA_TEST_ID}>
                                    <img src={IMG_NO_MACHINE_IMAGE} width={45} height={48} />
                                    <Typography marginLeft={2}>{item}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </InputField>
                    <InputField label={TextCommon.CUSTOMER_NAME_LABEL}>
                        <InputBase
                            type="text"
                            sx={styles.textInput}
                            placeholder={TextCommon.CUSTOMER_NAME_PLACEHOLDER}
                            name="customer"
                            value={machine?.customer}
                            onChange={e => handleOnChangeNewMachineObj(e)}
                        />
                    </InputField>
                    <InputField required label={TextCommon.TIME_ZONE_LABEL}>
                        <Select
                            renderValue={renderTimeZoneValue}
                            displayEmpty
                            sx={styles.selectField}
                            variant="standard"
                            disableUnderline
                            name="timeZone"
                            onChange={e => handleOnChangeNewMachineObj(e)}
                            data-testid={TestCommon.TIMEZONE_SELECT_BOX_DATA_TEST_ID}
                        >
                            {MachineCommon.TIME_ZONE_LIST?.map((item, idx) => (
                                <MenuItem key={idx} value={item.index} data-testid={TestCommon.TIMEZONE_ITEM_DATA_TEST_ID}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputField>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.dialogAction} data-testid={TestCommon.CREATE_MACHINE_DIALOG_ACTION_DATA_TEST_ID}>
                <Typography color={colors.validationError} marginRight={2}>
                    {validationError}
                </Typography>
                <Button variant="outlined" sx={styles.cancelButton} onClick={setCloseDialog} name="cancel">
                    {TextCommon.CANCEL}
                </Button>
                <Button variant="contained" sx={styles.createButton} onClick={onCreate} disabled={isDisabled} name="create">
                    {TextCommon.CREATE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateMachineDialog;
