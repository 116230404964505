import { TimeZone, IMachineItem, IDeleteMachineLogItem } from 'src/types/MachineManagement';
import { AimsQueryAgent, AimsUpdateAgent, ClientResponse } from 'nxg-http-client';
import TextCommon from './text.common';
import * as BaseCommon from './base.common';
import { ITableColumn } from 'nxg-ui-wrapper';
import { JSONUtils, capitalizeFirstLetterEachWord } from 'src/utils/format.util';
// machine management
export const BASE_URL = process.env.REACT_APP_BASE_URL || BaseCommon.EMPTY_STRING;
export const GET_MACHINE_LIST_PATH = '/machines';
export const GET_MACHINE_INFO_PATH = '/machines/:id';
export const CREATE_MACHINE_PATH = '/machines';
export const UPDATE_MACHINE_PATH = '/machines/:id';
export const DELETE_MACHINE_PATH = '/machines/:id';
export const GET_MODEL_LIST_PATH = '/machines/def/models';
export const GET_LOG_INFOMATION_PATH = '/loginfo/machines';
export const DELETE_MACHINE_LOG_PATH = '/:machine_id/log';
export const API_KEY = process.env.REACT_APP_API_KEY || BaseCommon.EMPTY_STRING;
export const DEFAULT_DATETIME = 'N/A';
export const DEFAULT_MACHINE_ITEM: IMachineItem = {
    id: BaseCommon.EMPTY_STRING,
    name: BaseCommon.EMPTY_STRING,
    customer: BaseCommon.EMPTY_STRING,
    model: BaseCommon.EMPTY_STRING,
    timeZone: {} as TimeZone,
    oldestDatetime: DEFAULT_DATETIME,
    latestDatetime: DEFAULT_DATETIME,
};

export const DEFAULT_DELETE_MACHINE_LOG_ITEM: IDeleteMachineLogItem = {
    id: BaseCommon.EMPTY_STRING,
    timeZone: BaseCommon.ZERO_NUMBER,
    mode: BaseCommon.DELETE_MODE_BEFORE,
};

export const TIME_ZONE_LIST = [
    { name: '(GMT -12:00) International Date Line West', index: -12 },
    { name: '(GMT -11:00) Midway Island, American Samoa', index: -11 },
    { name: '(GMT -10:00) Hawaii', index: -10 },
    { name: '(GMT -9:00) Alaska', index: -9 },
    { name: '(GMT -8:00) Pacific Time (US & Canada)', index: -8 },
    { name: '(GMT -7:00) Mountain Time (US & Canada)', index: -7 },
    { name: '(GMT -6:00) Central Time (US & Canada), Mexico City', index: -6 },
    { name: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima', index: -5 },
    { name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz, Georgetown', index: -4 },
    { name: '(GMT -3:00) Brazil, Buenos Aires', index: -3 },
    { name: '(GMT -2:00) Coordinated Universal, South Georgia', index: -2 },
    { name: '(GMT -1:00) Azores, Cape Verde Islands', index: -1 },
    { name: '(GMT +0:00) Western Europe Time, London, Lisbon, Casablanca', index: 0 },
    { name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', index: 1 },
    { name: '(GMT +2:00) Kaliningrad, South Africa', index: 2 },
    { name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', index: 3 },
    { name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', index: 4 },
    { name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', index: 5 },
    { name: '(GMT +6:00) Astana', index: 6 },
    { name: '(GMT +7:00) Bangkok, Hanoi, Jakarta', index: 7 },
    { name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', index: 8 },
    { name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', index: 9 },
    { name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', index: 10 },
    { name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia', index: 11 },
    { name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', index: 12 },
    { name: '(GMT +13:00) Apia, Nukualofa, Tokelau', index: 13 },
    { name: '(GMT +14:00) Line Islands, Kiritimati', index: 14 },
];
const SECOND_LEVEL_CONVERTED_POSITION = [0, 1];
const DEFAULT_CONVERTED_POSITION = [0, 0];
const EMPTY_ARRAY = [];

export const DEFAULT_TIME_ZONE = { name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', index: 9 };
export const QueryAgent = new AimsQueryAgent({ baseURL: BASE_URL }, API_KEY, {
    onResponse: (response: ClientResponse): Promise<ClientResponse> => {
        return new Promise<ClientResponse>(resolve => {
            switch (response.config.url) {
                case GET_MACHINE_LIST_PATH:
                case GET_MACHINE_INFO_PATH:
                case GET_LOG_INFOMATION_PATH:
                    response.data = JSONUtils.convertKeySnakeToCamel(response.data, SECOND_LEVEL_CONVERTED_POSITION);
                    break;
                default:
                    response.data = JSONUtils.convertKeySnakeToCamel(response.data, DEFAULT_CONVERTED_POSITION);
            }
            resolve(response);
        });
    },
});

export const UpdateAgent = new AimsUpdateAgent({ baseURL: BASE_URL }, API_KEY, {
    onResponse: (response: ClientResponse): Promise<ClientResponse> => {
        return new Promise<ClientResponse>(resolve => {
            response.data = JSONUtils.convertKeySnakeToCamel(response.data, DEFAULT_CONVERTED_POSITION);
            resolve(response);
        });
    },
});

export const DeleteLogAgent = new AimsUpdateAgent({ baseURL: BASE_URL }, API_KEY, {
    onResponse: (response: ClientResponse): Promise<ClientResponse> => {
        return new Promise<ClientResponse>(resolve => {
            response.data = JSONUtils.convertKeySnakeToCamel(response.data, EMPTY_ARRAY);
            resolve(response);
        });
    },
});
export const TIME_ZONE_KEY_NAME = 'timeZone';
export const ID_KEY_NAME = 'id';

export const CONSTANT_TEXT_MACHINE_ID = 'id';
export const CONSTANT_TEXT_MACHINE_NAME = 'name';
export const CONSTANT_TEXT_MODEL = 'model';
export const CONSTANT_TEXT_CUSTOMER_NAME = 'customer';
export const CONSTANT_TEXT_TIME_ZONE = 'timeZone';
export const CONSTANT_TEXT_OLDEST_DATE_TIME = 'oldestDatetime';
export const CONSTANT_TEXT_LATEST_DATE_TIME = 'latestDatetime';
export const CONSTANT_TEXT_ACTION = 'action';

export const CAMEL_CASE_LOG_TYPE_LIST = ['traceLog', 'alarmLog', 'waferLog'];

export const MACHINE_LIST_TABLE_COLUMNS: ITableColumn[] = [
    {
        title: TextCommon.MACHINE_ID_LABEL,
        key: CONSTANT_TEXT_MACHINE_ID,
    },
    {
        title: capitalizeFirstLetterEachWord(TextCommon.MACHINE_NAME_LABEL),
        key: CONSTANT_TEXT_MACHINE_NAME,
    },
    {
        title: TextCommon.MODEL_LABEL,
        key: CONSTANT_TEXT_MODEL,
    },
    {
        title: capitalizeFirstLetterEachWord(TextCommon.CUSTOMER_NAME_LABEL),
        key: CONSTANT_TEXT_CUSTOMER_NAME,
    },
    {
        title: capitalizeFirstLetterEachWord(TextCommon.TIME_ZONE_LABEL),
        key: CONSTANT_TEXT_TIME_ZONE,
    },
    {
        title: TextCommon.OLDEST_LOGTIME_LABEL,
        key: CONSTANT_TEXT_OLDEST_DATE_TIME,
    },
    {
        title: TextCommon.LATEST_LOGTIME_LABEL,
        key: CONSTANT_TEXT_LATEST_DATE_TIME,
    },
    {
        title: TextCommon.ACTION_LABEL,
        key: CONSTANT_TEXT_ACTION,
        align: 'center',
    },
];

export const DEFAULT_DELETE_DIALOG_WIDTH = '475px';
export const DEFAULT_BORDER_RADIUS = '0';
