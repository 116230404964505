import { Dispatch } from 'react';
import {
    BrowserStorageKey,
    BucketType,
    FileDb3,
    IThrowErrorUploadType,
    IUploadType,
    FileOfBucket,
    UploadObjectFile,
    S3StatusBucket,
    S3StatusObjectFile,
} from '../types';
import { AuthenticatedPayload } from '../store';
import { TypeCommon, BaseCommon, EquipmentCommon } from '../common';
import { AmplifyS3Config, AWSS3Config } from '../custom/type';
import { BrowserStorageUtil, ConfigurationUtil } from '../utils';
import s3Client from '../custom/amplifys3.client';

const fetchEquipmentLogFromS3 = async (): Promise<S3StatusBucket> => {
    const sqlite = await fetchSingleDataFromS3(BucketType.SQLITE);
    return sqlite;
};

const fetchBlockingFromS3 = async (): Promise<S3StatusBucket> => {
    return await fetchSingleDataFromS3(BucketType.BLOCKING);
};

const fetchSingleDataFromS3 = async (type: BucketType): Promise<S3StatusBucket> => {
    const objects = (await s3Client.getInstance(type).list()).items as unknown as S3StatusObjectFile[];
    const bucketName = s3Client.getConfigOfInstance(type)?.AWSS3.bucket;
    return transformToS3StatusBucket(objects, bucketName);
};

const updateBlockingFlag = async (value: boolean): Promise<void> => {
    try {
        if (value === true) {
            await s3Client.getInstance(BucketType.BLOCKING).put(BaseCommon.FILENAME_BLOCKING_FLAG, BaseCommon.FILENAME_BLOCKING_FLAG, {
                errorCallback: (error: any) => {
                    throw error;
                },
            });
        } else {
            await s3Client.getInstance(BucketType.BLOCKING).remove(BaseCommon.FILENAME_BLOCKING_FLAG);
        }
    } catch (error: any) {
        console.error('BLOCKING_FLAG::ERROR::', error);
        throw error;
    }
};

const transformToS3StatusBucket = (data: S3StatusObjectFile[], name: string | undefined): S3StatusBucket => {
    const objects = data
        .map((item: S3StatusObjectFile) => {
            const split = item.path.split(BaseCommon.CHARACTER_SLASH);
            item.equipmentId = split[0];
            item.key = split[1];
            return item;
        })
        .filter((item: S3StatusObjectFile) => item.key && item.key.trim());

    const totalSize = data.reduce((total: number, item: S3StatusObjectFile) => total + item.size, 0);
    return {
        name,
        totalFile: objects.length,
        totalSize,
        objects,
    };
};

const buildResourceEntry = (lcFiles: FileDb3[]): FileOfBucket => {
    const sqlite = lcFiles.reduce((result: UploadObjectFile[], file: FileDb3) => transformFileToResource(result, file), []);
    const sqliteBucketName = s3Client.getConfigOfInstance(BucketType.SQLITE).AWSS3.bucket;
    const sqliteResources: FileOfBucket = buildResourceForUpload(sqliteBucketName, sqlite);
    return sqliteResources;
};

const transformFileToResource = (result: UploadObjectFile[], file: FileDb3): UploadObjectFile[] => {
    const folder = file.path?.length
        ? file.path?.split(file.name)[0].split(BaseCommon.CHARACTER_SLASH).slice(-2, -1).join(BaseCommon.CHARACTER_SLASH)
        : BaseCommon.EMPTY_STRING;
    const isMatchDb3File = file.name.match(EquipmentCommon.REGEX_DB3_FILE);
    if (!isMatchDb3File) {
        return result;
    }
    const isStartWithNameOfFolder = file.name.startsWith(folder);
    if (isStartWithNameOfFolder) {
        result.push({
            isError: false,
            size: file.size,
            filename: file.name,
            lastModified: file.lastModified,
            progress: 0,
            folder,
            file,
        });
        return result;
    }

    return result;
};

const buildResourceForUpload = (s3Provider: string, objects: UploadObjectFile[]): FileOfBucket => {
    const totalSize = objects.reduce((total: number, item: UploadObjectFile) => total + item.size, 0);
    return {
        name: s3Provider,
        isError: false,
        progress: 0,
        totalFile: objects.length,
        objects,
        totalSize,
    };
};

const calculateUploadingProgress = async (
    uploadingFiles: FileOfBucket,
    equipmentId: string,
    dispatch: Dispatch<AuthenticatedPayload>,
): Promise<void> => {
    for await (const object of uploadingFiles.objects) {
        try {
            await s3Client
                .getInstanceByBucketName(uploadingFiles.name as string)
                .put([equipmentId, object.filename].join(BaseCommon.CHARACTER_SLASH), object.file, {
                    onProgress: (progress: any) => handleProgressCallback(uploadingFiles, object, progress, dispatch),
                    errorCallback: handleErrorCallback,
                });
        } catch (err: any) {
            const uploaded = handleProgressError(uploadingFiles, object);
            const payload: IThrowErrorUploadType = {
                uploadingFile: uploaded,
            };
            dispatch(TypeCommon.setThrowErrorUploadType(payload));
        }
    }
};

const handleErrorCallback = (err: any): void => {
    throw err;
};

const handleProgressError = (upload: FileOfBucket, object: UploadObjectFile): FileOfBucket => {
    object.isError = true;
    object.file = null;
    upload.isError = true;
    const newKb = object.size - (object.progress * object.size) / BaseCommon.COMPLETED_PERCENTAGE_NUM;
    object.progress = BaseCommon.COMPLETED_PERCENTAGE_NUM;
    upload = calculateProgressForBucket(upload, newKb);
    return upload;
};

const handleProgressCallback = (upload: FileOfBucket, object: UploadObjectFile, progress: any, dispatch: Dispatch<AuthenticatedPayload>): void => {
    const total = progress.totalBytes;
    if (total === 0) {
        return;
    }

    const oldProgress = object.progress;
    const newProgress = (progress.transferredBytes / total) * BaseCommon.COMPLETED_PERCENTAGE_NUM;

    if (newProgress < 99 && Math.trunc(newProgress) === Math.trunc(object.progress)) {
        return;
    }

    object.progress = newProgress;

    // Clear file if uploaded successful
    if (object.progress === BaseCommon.COMPLETED_PERCENTAGE_NUM) {
        object.file = null;
    }

    const newKb = progress.loaded - (oldProgress * progress.totalBytes) / BaseCommon.COMPLETED_PERCENTAGE_NUM;
    // calculate and update the progress for bucket
    upload = calculateProgressForBucket(upload, newKb);
    const payload: IUploadType = {
        uploadingFile: upload,
    };
    dispatch(TypeCommon.setUploadType(payload));
};

const isCompletedProgress = (uploadingFiles: FileOfBucket): boolean => {
    if (isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isCompletedUploaded(uploadingFiles);
};

const isCompletedUploaded = (uploadingFiles: FileOfBucket): boolean => {
    let totalProgress = BaseCommon.COMPLETED_PERCENTAGE_NUM;
    if (uploadingFiles.objects.length !== BaseCommon.DEFAULT_OBJECT_LENGTH) {
        const eachProgress = uploadingFiles.objects.reduce((_total: number, uploadFile: UploadObjectFile) => {
            return _total + uploadFile.progress;
        }, BaseCommon.ZERO_NUMBER);
        const totalSubProgress = eachProgress / uploadingFiles.objects.length;
        totalProgress = totalSubProgress;
    }

    return totalProgress === BaseCommon.COMPLETED_PERCENTAGE_NUM;
};

const isEmptyLocalFiles = (uploadingFiles: FileOfBucket): boolean => {
    return uploadingFiles.objects.length === BaseCommon.DEFAULT_OBJECT_LENGTH;
};

const calculateProgressForBucket = (upload: FileOfBucket, newKb: number): FileOfBucket => {
    const progressBucket = upload;

    const uploadedProgress = progressBucket.progress + (newKb / progressBucket.totalSize) * BaseCommon.COMPLETED_PERCENTAGE_NUM;
    const checkIsAllFilesUploaded = progressBucket.objects.some((file: UploadObjectFile) => file.progress < BaseCommon.COMPLETED_PERCENTAGE_NUM);
    upload = {
        ...progressBucket,
        progress:
            Math.ceil(uploadedProgress) > BaseCommon.COMPLETED_PERCENTAGE_NUM || !checkIsAllFilesUploaded
                ? BaseCommon.COMPLETED_PERCENTAGE_NUM
                : uploadedProgress,
    };

    return upload;
};

const isErrorAnyFileUploaded = (uploadingFiles: FileOfBucket): boolean => {
    if (isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isAnyFileError(uploadingFiles);
};

const isErrorAllFilesUploaded = (uploadingFiles: FileOfBucket): boolean => {
    if (isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isAllFilesError(uploadingFiles);
};

const isAllFilesError = (uploadingFiles: FileOfBucket): boolean => {
    return (
        uploadingFiles.isError &&
        uploadingFiles.objects.every((subFile: UploadObjectFile) => {
            return subFile.isError;
        })
    );
};

const isAnyFileError = (uploadingFiles: FileOfBucket): boolean => {
    return uploadingFiles.objects.length !== BaseCommon.DEFAULT_OBJECT_LENGTH && uploadingFiles.isError;
};

const getBucketAndRegion = (url: string): AWSS3Config => {
    let bucket = '';
    let region = '';
    try {
        const urlParser = new URL(url);
        const pathSplit = urlParser.pathname.split(BaseCommon.CHARACTER_SLASH);
        bucket = pathSplit[pathSplit.length - 1];
        region = urlParser.searchParams.get(BaseCommon.STRING_REGION) as string;
    } catch (error) {
        console.error('ERROR:getBucketAndRegion:: ', error);
    }
    return ConfigurationUtil.buildS3Config(bucket, region);
};

const retrieveConfigFromSession = (configs: AmplifyS3Config<BucketType>[]): (AmplifyS3Config<BucketType> | null)[] => {
    const region = BrowserStorageUtil.retrieveFromSession<string>(BrowserStorageKey.S3_REGION) ?? BaseCommon.EMPTY_STRING;
    const configIntoSession = configs.reduce((bucketConfigs: (AmplifyS3Config<BucketType> | null)[], config: AmplifyS3Config<BucketType>) => {
        const bucketName = BrowserStorageUtil.retrieveFromSession<string>(config.sessionKey) ?? BaseCommon.EMPTY_STRING;
        if (!bucketName || !region) {
            bucketConfigs.push(null);
        } else {
            console.log('RetrieveConfigFromSession:: ', { bucketName, region });
            const s3Config: AmplifyS3Config<BucketType> = ConfigurationUtil.buildAmplifyS3Config(config.name, bucketName, region, config.sessionKey);
            bucketConfigs.push(s3Config);
        }
        return bucketConfigs;
    }, []);

    return configIntoSession;
};

const storeConfigToSession = (config: AmplifyS3Config<BucketType>) => {
    BrowserStorageUtil.storeToSession(BrowserStorageKey.S3_REGION, config.AWSS3.region);
    BrowserStorageUtil.storeToSession(config.sessionKey, config.AWSS3.bucket);
};

const initialConfigToSession = (configs: AmplifyS3Config<BucketType>[]): AmplifyS3Config<BucketType>[] => {
    const configIntoSession = retrieveConfigFromSession(configs);
    const hasNullValue = configIntoSession.some((item: AmplifyS3Config<BucketType> | null) => item === null);

    if (hasNullValue) {
        configs.forEach((config: AmplifyS3Config<BucketType>) => storeConfigToSession(config));
    }
    return retrieveConfigFromSession(configs) as AmplifyS3Config<BucketType>[];
};

export {
    buildResourceEntry,
    fetchEquipmentLogFromS3,
    fetchBlockingFromS3,
    calculateUploadingProgress,
    isCompletedProgress,
    isEmptyLocalFiles,
    isErrorAllFilesUploaded,
    isErrorAnyFileUploaded,
    getBucketAndRegion,
    retrieveConfigFromSession,
    storeConfigToSession,
    initialConfigToSession,
    updateBlockingFlag,
};
