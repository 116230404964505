import React from 'react';
import { Box, Typography } from 'nxg-ui-wrapper';
import { InputFieldStyle as styles } from './InputFieldStyle';
import { TextCommon, TestCommon } from 'src/common';

interface InputFieldProps {
    required?: boolean;
    label: string;
    children?: React.ReactNode;
    isError?: boolean;
}
const ERROR_BORDER = '1px solid #B40000';
const renderLabel = (isRequired: boolean | undefined, label: string): JSX.Element => {
    return (
        <Box sx={styles.labelWrapper}>
            <Typography>{label}</Typography>
            {isRequired && <Typography>{TextCommon.ASTERISK}</Typography>}
        </Box>
    );
};

const InputField = (props: InputFieldProps): JSX.Element => {
    const { required, label, children, isError } = props;
    const applyBorderError = () => {
        if (isError) {
            return { ...styles.wrapper, border: ERROR_BORDER };
        }
        return styles.wrapper;
    };
    return (
        <Box sx={applyBorderError()} data-testid={TestCommon.INPUT_FIELD_DATA_TEST_ID}>
            <Box>{renderLabel(required, label)}</Box>
            <Box sx={styles.inputChildren}>{children}</Box>
        </Box>
    );
};

export default InputField;
