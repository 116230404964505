import { Heading } from '@aws-amplify/ui-react';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { AuthFormFields } from '@aws-amplify/ui/dist/types/types/authenticator/form';

import { IC_LOGO_ACCRETECH } from '../../assets';
import { TextCommon, TestCommon } from '../../common';
import styles from './SignIn.module.css';

const ICON = <img src={IC_LOGO_ACCRETECH} />;
const SignIn: DefaultComponents = {
    SignIn: {
        Header(): JSX.Element {
            return (
                <Heading className={styles.heading} level={2} data-testid={TestCommon.SIGN_IN_HEADER_DATA_TEST_ID}>
                    {ICON}
                </Heading>
            );
        },
        Footer(): JSX.Element {
            return <></>;
        },
    },
};
const formFields: AuthFormFields = {
    signIn: {
        username: {
            labelHidden: true,
            placeholder: TextCommon.USERNAME_PLACEHOLDER,
        },
        password: {
            labelHidden: true,
            placeholder: TextCommon.PASSWORD_PLACEHOLDER,
        },
    },
};

export { SignIn, formFields };
